import AsyncStorage from '@react-native-community/async-storage'; 
import axios from 'axios';
import swal from 'sweetalert';

/**
 * ## DatosSPG 
 * ### Clase general de SoftPlace Software
 * 
 * Es una clase que sirve para controlar todo lo que sea conexión y datos en general, seguridad y algunas acciones por default como inserción, selección etc de datos base y la creación de 
 * cache para el funcionamiento general del sistema
 */
class DatosSPG 
{
    /**
     * Obtiene el idUsuario logueado en el sistema, dato guardado en local Storage.
    */
    idUsuario() 
    {
        let nUsuario = 0;

        AsyncStorage.getItem('idUsuario', (error, result) => {
            if(error) 
                nUsuario = 0;
            else if(result) 
                nUsuario = result;
            else if(result === null) 
                nUsuario = 0;
        });
        
        return nUsuario;
    }

    /**
     * Metodo que permite cambiar de usuario y los datos del mismo en la sesión
     * Si se envía un array en el parámetro aUsuario, este debe conyener todos los datos de una sesión
     * Si se envía un array vacío, o un array incorrecto o sin parametros, se cerrará la sesión del usuario
     */
    async CambiarUsuario(aUsuario = [])
    {
        if (aUsuario["idUsuario"])
        {
            AsyncStorage.setItem('idUsuario', aUsuario["idUsuario"]);
            AsyncStorage.setItem('idSucursal', aUsuario["idSucursal"]); 
            AsyncStorage.setItem('idDeposito', aUsuario["idDeposito"]); 
            AsyncStorage.setItem('idPuntoVenta', aUsuario["idPuntoVenta"]); 
            AsyncStorage.setItem('idTipoContribuyente', aUsuario["idTipoContribuyente"]); 
            AsyncStorage.setItem('usuNombre', aUsuario["usuNombre"]); 
            AsyncStorage.setItem('usuFoto', aUsuario["usuFoto"]); 
            AsyncStorage.setItem('pvNumero', aUsuario["pvNumero"]); 
            AsyncStorage.setItem('pvDescrip', aUsuario["pvDescrip"]); 
            AsyncStorage.setItem('sucDescrip', aUsuario["sucDescrip"]); 
            AsyncStorage.setItem('usuLogin', aUsuario["usuLogin"]); 
            AsyncStorage.setItem('usuFechaCaduque', aUsuario["usuFechaCaduque"]); 
            AsyncStorage.setItem('bdFechaCaduca', aUsuario["bdFechaCaduca"]); 
            AsyncStorage.setItem('sucRazonSocial', aUsuario["sucRazonSocial"]); 
            AsyncStorage.setItem('sucTelefonos', aUsuario["sucTelefonos"]); 
            AsyncStorage.setItem('sucCuit', aUsuario["sucCuit"]); 
            AsyncStorage.setItem('sucIngresosBrutos', aUsuario["sucIngresosBrutos"]); 
            AsyncStorage.setItem('sucDomicilio', aUsuario["sucDomicilio"]); 
            AsyncStorage.setItem('tyDescrip', aUsuario["tyDescrip"]); 
            AsyncStorage.setItem('tyAbrev', aUsuario["tyAbrev"]); 
            AsyncStorage.setItem('sucFechaInicio', aUsuario["sucFechaInicio"]); 
            AsyncStorage.setItem('sucLogo', aUsuario["sucLogo"]); 
            AsyncStorage.setItem('idPais', aUsuario["idPais"]); 
            AsyncStorage.setItem('paAbrev', aUsuario["paAbrev"]); 
            AsyncStorage.setItem('sucPrecioEspecial', aUsuario["sucPrecioEspecial"]);
            AsyncStorage.setItem('usuURLDefa', aUsuario["usuURLDefa"]);
            AsyncStorage.setItem('usuCambiarClave', (aUsuario["usuCambiarClave"] === "1" ? 1 : 0));
            AsyncStorage.setItem('usuAdmin', (aUsuario["usuAdmin"] && aUsuario["usuAdmin"] === "1" ? 1 : 0));
            
            AsyncStorage.setItem('idBD', aUsuario["idBD"]);
            AsyncStorage.setItem('bdSeguridad', aUsuario["bdSeguridad"]);
            AsyncStorage.setItem('bdNombreBDPagina', aUsuario["bdNombreBDPagina"]);
            AsyncStorage.setItem('bdServer', aUsuario["bdServer"]);
            AsyncStorage.setItem('Cliente-SP', aUsuario["bdCodigo"]);
        }
        else
        {
            this.EliminarDato("Datos-Base")
            this.EliminarDato("idUsuario")
            this.EliminarDato("ConfiguracionesHoriz")
            this.EliminarDato("TiposProductos")
            this.EliminarDato("SubTiposProductos")
            this.EliminarDato("MateriasPrimas")
            this.EliminarDato("Monedas")
            this.EliminarDato("Unidades")
            this.EliminarDato("Depositos")
            this.EliminarDato("Proveedores")
            this.EliminarDato("Transportes")
            this.EliminarDato("Usuarios")
            this.EliminarDato("TiposUsuarios")
            this.EliminarDato("TiposComprobantes")
            this.EliminarDato("Configuraciones")
            this.EliminarDato("PuntosVenta")
            this.EliminarDato("TiposDocumentos")
            this.EliminarDato("TiposContribuyentes")
            this.EliminarDato("TiposEntidades")
            this.EliminarDato("TiposCuentasBancarias")
            this.EliminarDato("Bancos")
            this.EliminarDato("PaquetesEnvios")
            this.EliminarDato("Provincias")
            this.EliminarDato("Financiaciones")
            this.EliminarDato("TiposPeriodos")
            this.EliminarDato("FormasPago")
            this.EliminarDato("ImpuestosInternos")
            this.EliminarDato("MateriaPrima")
            this.EliminarDato("Propiedades")
            this.EliminarDato("TipoCompXTipoCont")
            this.EliminarDato("bdSeguridad")
            this.EliminarDato("bdServer")
            this.EliminarDato("bdNombreBDPagina")
            this.EliminarDato("idDeposito")
            this.EliminarDato("idPuntoVenta")
            this.EliminarDato("idTipoContribuyente")
            this.EliminarDato("usuNombre")
            this.EliminarDato("usuFoto")
            this.EliminarDato("pvNumero")
            this.EliminarDato("pvDescrip")
            this.EliminarDato("usuLogin")
            this.EliminarDato("sucRazonSocial")
            this.EliminarDato("sucTelefonos")
            this.EliminarDato("sucCuit")
            this.EliminarDato("sucIngresosBrutos")
            this.EliminarDato("sucDomicilio")
            this.EliminarDato("tyDescrip")
            this.EliminarDato("tyAbrev")
            this.EliminarDato("sucFechaInicio")
            this.EliminarDato("sucLogo")
            this.EliminarDato("sucPrecioEspecial") 
            this.EliminarDato("usuURLDefa") 
            this.EliminarDato("usuAdmin") 
            this.EliminarDato("usuCambiarClave") 
            this.EliminarDato("idPais") 
            this.EliminarDato("paAbrev") 
        }

        window.location = "/";
    }

    /**
     * Obtiene el certificado de confianza de conexión del sistema, dato guardado en local Storage.
    */
    Certificado() 
    {
        let cCertificado = null;

        AsyncStorage.getItem('Certificado-SPG', (error, result) => {
			if(error) 
                cCertificado = null;
			else if(result) 
                cCertificado = result;
			else if(result === null) 
                cCertificado = null;
        });
          
        return cCertificado;
    }

    /**
     * Obtiene el Key del certificado de conexión del sistema, dato guardado en local Storage.
    */
    Key()  
    {
        let cKey = null;

        AsyncStorage.getItem('Key-SPG', (error, result) => {
			if(error) 
                cKey = null;
			else if(result) 
                cKey = result;
			else if(result === null) 
                cKey = null;
        });
          
          return cKey;
    }

    /**
     * Permite ver un dato guardado localmente 
     * 
     * @param {*} cDato: Es el nombre del datoq ue desea buscar
     * @param {*} bArray: Define si se devuelve el resultado como array (true) o solo como texto (false o default)
    */
    VerDato(cDato, bArray = false)  
    {
        let cKey = null 

        AsyncStorage.getItem(cDato, (error, result) => 
        {
            if(error) 
            {   
                cKey = bArray ? "[]" : null 
            }
			else if(result) 
            {
                cKey = result 
            }
			else if(result === null) 
            {
                cKey = bArray ? "[]" : null 
            }
        });
        
        if (!bArray)
            return cKey 
        else
        {
            //console.log(cDato) 
            //console.log(cKey) 
            //console.log("----")

            let aArrayRes = JSON.parse(cKey)
            //console.log(aArrayRes)

            return aArrayRes
        }
    }

    /**
     * Permite guardar un dato localmente, sea este nuevo o existente 
    */
    GuardarDato(cDato, cValor)
    {
        AsyncStorage.setItem(cDato, cValor);
    }

    /**
     * Permite Eliminar un dato localmente, sea este nuevo o existente 
    */
    EliminarDato(cDato)
    {
        AsyncStorage.removeItem(cDato);
    }

    /**
     * Obtiene el Token del certificado de conexión del sistema, dato guardado en local Storage.
    */
    Token()  
    {
        let cToken = null;

        AsyncStorage.getItem('Token-SPG', (error, result) => {
			if(error) 
                cToken = null;
			else if(result) 
                cToken = result;
			else if(result === null) 
                cToken = null;
        });
          
        return cToken;
    }

    /**
     * Obtiene la URL del servidor donde leer los datos de la API
    */
    Server() 
    {
        return "https://api.softplacesoftware.com.ar/";
    }

    /**
     * Se conecta a la API del sistema, y manda un grupo de parámetros enviados por POST, devolviendo un JSON.
     * 
     * PARÁMETROS: deben enviarse en un array, con el formato { nombre: _nombre_del_parametro, valor: _valor_del_parametro }
     * 
     * RETORNA: array con el siguiente formato { Error: mensaje_error, token: numero_token}, 
     * en caso que todo esté bien, error va a ir vacio, y en caso que haya un error, el token viene en cero
    */
    async LeerAPI(cGrupo, cModulo, aParametros, cToken = "")
    {
        const dFecha = new Date()
        const nAno = dFecha.getFullYear()
        const nMes = dFecha.getMonth() + 1
        const nDia = dFecha.getDate()
        const nHora = dFecha.getHours()
        const nMinuto = dFecha.getMinutes()
        const nFechaCalculada = nMinuto + (nHora * 60) + (nDia * 24 * 60) + (nMes * 30 * 24 * 60) + (nAno * 365 * 30 * 24 * 60)
        const aPostParam = new FormData();

        this.GuardarDato("Ultima-Accion", nFechaCalculada) 
        
        if (!cToken)
        {
            cToken = this.Token();
        }
        
        if (aParametros)
        {
            aParametros.map((param) => (aPostParam.append(param.nombre, param.valor)));
        }
        
        // agrega el parámetro url, o ubicacion, que indica dónde se realizó la acción con la api
        aPostParam.append("url-possum", window.location.href);
        
        if (cToken !== null)
        {
            aPostParam.append("token", cToken);
        }

        let jRta = []

        // Intenta hacer la peticion a la API
        try 
        {
            //console.log("Haciendo Post a " + cModulo)
            axios.defaults.timeout = 600000; // tiempo de espera 10 min
            
            const respuesta = await axios.post(this.Server() + cGrupo + cModulo + ".php", aPostParam)
            //console.log(this.Server() + cGrupo + cModulo + ".php");

            //console.log(respuesta)

            jRta = await respuesta.data;

            //console.log(jRta)
            
            if (typeof jRta["RESULTADO"] !== "object")
            {
                Object.defineProperty(jRta, "status", { value: respuesta.status, "writable": true })
            }
            else 
            {
                let aError = jRta["RESULTADO"][0]
                
                jRta = [{ "IDERROR": 7, "ERROR": "Error producido en el servidor, " + aError["ERROR"], "token": "0" }];

                Object.defineProperty(jRta, "status", { value: 404, "writable": true })
            }
        }
        catch (error)
        {
            /*
                Status 200 funcionamiento normal
                error 3 o 400 error desconocido
                error 5 o 401 licencia caducada
                error 7 o 402 error de ejecucion sql
                error 8 o 405 error de validacion en sql
                error 2 o 409 error de consulta vacia
                error 6 o 411 datos incorrectos
                error 1 o 411 error de parametros incorrectos
                error 4 o 423 token vencido
            */

            //console.log(error)

            let nError = 400

            if (error.message.includes("Network Error"))
            {
                nError = 521
                jRta = [{ "IDERROR": 3, "ERROR": "No hay Internet " + error.message, "token": cToken }];
            }
            
            if (error.message.indexOf("400") > 0)
            {
                nError = 400
                jRta = [{ "IDERROR": 3, "ERROR": "Error desconocido " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("401") > 0)
            {
                nError = 401
                jRta = [{ "IDERROR": 5, "ERROR": "Licencia caducada " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("402") > 0)
            {
                nError = 402
                jRta = [{ "IDERROR": 7, "ERROR": "Error de ejecución SQL: " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("405") > 0)
            {
                nError = 405
                jRta = [{ "IDERROR": 7, "ERROR": "Error de validación en SQL: " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("409") > 0)
            {
                nError = 409
                jRta = [{ "IDERROR": 2, "ERROR": "Consulta vacia " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("411") > 0)
            {
                nError = 411
                jRta = [{ "IDERROR": 1, "ERROR": "Error de parametros incorrectos " + error.message, "token": cToken }];
            }

            if (error.message.indexOf("423") > 0)
            {
                nError = 423
                jRta = [{ "IDERROR": 1, "ERROR": "Token Vencido " + error.message, "token": cToken }];

                this.RenovarToken()
            }
            
            if (error.message.indexOf("500") > 0)
            {
                jRta = [{ "IDERROR": 3, "ERROR": "Error desconocido hay un error en el php del back-end", "token": cToken }];
            }
            
            if (error.message.indexOf("522") > 0)
            {
                jRta = [{ "IDERROR": 3, "ERROR": "La respuesta ha tardado demasiado, quizá tengas problemas de internet", "token": cToken }];
            }
            
            if (jRta)
            { 
                Object.defineProperty(jRta, "status", { value: nError, "writable": true })
            }
            else 
            {
                jRta = [{"estado": "No se encontró el módulo " + this.Server() + cGrupo + cModulo + " o no se devolvió lo que se esperaba"}]
                
                Object.defineProperty(jRta, "status", { value: 404, "writable": true })
            }
        }

        return await jRta;
    }

    /**
     * ## LlamarURL
     * 
     * ### Método que llama a una URL que no esté dentro de la API, es decir, puede ser utilizada para hacer Web Hooking 
     * 
     * @param {string} cURL 
     * @param {array} aParametros 
     * @param {int} post indica que tipo de accion realiza: 
     *  - 0: GET
     *  - 1: POST (default)
     *  - 2: DELETE
     *  - 3: PATCH
     * @returns devuelve una promesa con la respuesta de la url
     */
    LlamarURL(cURL, aParametros, post = 1)
    {
        const aPostParam = new FormData();
        
        if (aParametros)
        {
            aParametros.map((param) => (aPostParam.append(param.nombre, param.valor)));
        }

        return new Promise(function(resolve, reject) 
        {
            switch (post)
            {
                case 1:
                    console.log("Haciendo POST a " + cURL)
                    
                    axios.post(cURL, aPostParam).then((data) =>
                    { 
                        if (data.status === 200 || data.status === 201)
                        {
                            resolve(data)
                        }
                        else 
                        {
                            reject(data)
                        }
                    }).catch((data) => 
                    {
                        reject(data, data.status)
                    })

                    break 
                case 0:
                    console.log("Haciendo GET a " + cURL)

                    axios.get(cURL).then((data) =>
                    { 
                        if (data.status === 200 || data.status === 201)
                        {
                            resolve(data)
                        }
                        else 
                        {
                            reject(data)
                        }
                    }).catch((data, status) => reject(data, data.status))

                    break 
                case 2:
                    console.log("Haciendo DELETE a " + cURL)

                    axios.delete(cURL, aPostParam).then((data) =>
                    { 
                        if (data.status === 200 || data.status === 201)
                        {
                            resolve(data)
                        }
                        else 
                        {
                            reject(data)
                        }
                    }).catch((data, status) => reject(data, data.status))

                    break
                case 3:
                    console.log("Haciendo PATCH a " + cURL)

                    axios.patch(cURL, aPostParam).then((data) =>
                    { 
                        if (data.status === 200 || data.status === 201)
                        {
                            resolve(data)
                        }
                        else 
                        {
                            reject(data)
                        }
                    }).catch((data) => 
                    { 
                        console.log(data);
                        reject(data, data.status)
                    })

                    break
                default:
            }
        })
    }

    /** 
     * Renueva el token y elimina el token anterior, este es necesario para realizar cualquier acción en el servidor
    */
    RenovarToken()
    {
        const aParametros = [{
                                nombre: "certificado", 
                                valor: this.Certificado()
                            },
                            {
                                nombre: "key", 
                                valor: this.Key()
                            },
                            {
                                nombre: "equipo", 
                                valor: this.VerDato("Equipo")
                            }]
        
        this.LeerAPI("seguridad/", "obtener-token", aParametros).then((jRta) =>
        { 
            this.GuardarDato('Token-SPG', jRta["token"]) 
            
            if (!this.idUsuario)
            {
                setTimeout(() => 
                {
                    this.CambiarUsuario()
                }, 1500);
            }
        })
    }

    VerBDToken(cToken)
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()

            // Busca en la base de datos, todos los tipos de producto
            let aParametros = [{
                                  nombre: "limpiarcache", 
                                  valor: 0
                              }]
            
            //console.log(aParametros)

            spg.LeerAPI("seguridad/", "ver-bd-token", aParametros, cToken)
                .then((aRta) => 
                {
                    resolve(aRta)
                }).catch(() =>
                {
                    

                    reject(0)
                });
        })
    }

    /** 
     * Busca los datos de el cliente, numero de Base de Datos y nombre de pagina web
    */
    BuscarDatosBD(cDato = "")
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()

            spg.LeerAPI("general/", "VerDatosBD").then((bd) =>
            { 
                if (bd)
                {
                    spg.GuardarDato('idBD', bd["idBD"]);
                    spg.GuardarDato('bdNombreBDPagina', bd["bdNombreBDPagina"]);
                    spg.GuardarDato('Cliente-SP', bd["bdCodigo"]);

                    if (cDato)
                    {
                        resolve(bd[cDato])
                    }
                    else 
                    {
                        resolve("")
                    }
                }
                else 
                {
                    reject("")
                }
            }).catch(() =>
            {
                reject("")
            })
        })
    }

    /** 
     * ## Busca los datos base/maestros de la base de datos
     * Tipos subtipos de producto, materias primas, tipos de comprobante, tipos de entidades etc
     * 
     * Recibe como parametro un array con el formato ["Tabla1", "Tabla2", "Tabla3", ...] para determinar cuales son las tablas que desea buscar
     * 
     * Recuerde que si no envía parametro, trae todos los conjuntos de datos
     * 
     * ### Los tipo sde tabla posibles son los siguientes:
     * "TiposProductos", "SubTiposProductos", "MateriaPrima", "FormasPago", "Unidades", "Depositos", "Proveedores", "Transportes", "Sucursales", "Usuarios", "TiposComprobantes", "Configuraciones", "PuntosVenta", "TiposDocumentos", "TiposContribuyentes", "TiposEntidades", "TiposCuentasBancarias", "Bancos", "PaquetesEnvios", "Provincias"
    */
    BuscarDatosBase(aTablas = [], bRedireccionar = true)
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()

            // si no viene una tabla especifica, hace todo el select en una sola instancia, sino, hace tabla por tabla
            if (aTablas.length === 0)
            {
                // Busca todas las tablas juntas en SQL

                spg.GuardarDato("ConfiguracionesHoriz", [])
                
                const aParametros = [{
                                        nombre: "valor",
                                        valor: ""
                                    },
                                    {
                                        nombre: "parametro",
                                        valor: ""
                                    }]
                
                // usa el ws directo, que trae todo en un solo select
                spg.LeerAPI("base/", "ver-tablas-base", aParametros).then((jRta) => 
                {
                    if (jRta.status === 200)
                    {
                        let aConfiguraciones = []

                        for (let x1 = 0; x1 < jRta.length; x1++)
                        { 
                            // sólo va poniendo en las cookies, las tablas q vienen como JSON, Configuraciones viene como una tabla comun, ya que las config suelen dar problema al armar un json
                            if (jRta[x1].tabla === "Configuraciones")
                            {
                                aConfiguraciones.push(jRta[x1])
                            }
                            else 
                            { 
                                if (jRta[x1].tabla !== "version-cache")
                                {
                                    AsyncStorage.setItem(jRta[x1].tabla, jRta[x1].det.replace(", {  }, ", ""))

                                    if (document.getElementById(jRta[x1].tabla))
                                    {
                                        document.getElementById(jRta[x1].tabla).classList.add("fondo-sp")
                                        document.getElementById(jRta[x1].tabla).classList.remove("falta")
                                    }
                                }
                                else 
                                {
                                    spg.GuardarDato("version-cache", JSON.stringify(jRta[x1].version));
                                }
                            }
                        }

                        if (aConfiguraciones.length)
                        {
                            // Si es configuraciones, arma un json comun, desde el array
                            AsyncStorage.setItem("Configuraciones", JSON.stringify(aConfiguraciones))

                            document.getElementById("Configuraciones").classList.add("fondo-sp")
                            document.getElementById("Configuraciones").classList.remove("falta")
                        }
                    }
                    else
                    {
                        // Agregar Tratamiento por error, en caso que sea 409 es porque la consulta vino vacía
                        if (jRta.status !== 409)
                        {
                            swal("Ouch!", "Error: Se produjo un error al los buscar datos, inténtelo de nuevo por favor", "error").then(() => this.BuscarDatosBase(aTablas, bRedireccionar))
                        }
                    }
                })
            } 
            else 
            {
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposProductos"))
                {
                    // Busca en la base de datos, todos los tipos de producto -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoProducto"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tpDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "tpServicio, tpCombo, tpInventariable, tpIdentificable, tpOrdenTrabajo, tpFabricacion, tpVerWEB, tpDestacado, tpCodigo"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposProductos"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    { 
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposProductos', JSON.stringify(rta))

                                if (document.getElementById('TiposProductos'))
                                {
                                    document.getElementById('TiposProductos').classList.add("fondo-sp")
                                    document.getElementById('TiposProductos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposProductos', "[]")

                                if (document.getElementById('TiposProductos'))
                                {
                                    document.getElementById('TiposProductos').classList.add("fondo-sp")
                                    document.getElementById('TiposProductos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposProductos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposProductos"])

                        reject(rta)
                    }) 
                } 
                
                if (/*aTablas.length === 0 ||*/ aTablas.includes("SubTiposProductos"))
                {
                    // Busca en la base de datos, todos los subtipos de producto -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idSubTipoProducto"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "stpDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "stpVerWEB, stpCodigo, idTipoProducto"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "SubTiposProductos"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('SubTiposProductos', JSON.stringify(rta))

                                if (document.getElementById('SubTiposProductos'))
                                {
                                    document.getElementById('SubTiposProductos').classList.add("fondo-sp")
                                    document.getElementById('SubTiposProductos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('SubTiposProductos', "[]")

                                if (document.getElementById('SubTiposProductos'))
                                {
                                    document.getElementById('SubTiposProductos').classList.add("fondo-sp")
                                    document.getElementById('SubTiposProductos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["SubTiposProductos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["SubTiposProductos"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("MateriaPrima"))
                {
                    // Busca en la base de datos, todas las materias primas -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idMateriaPrima"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "mpDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "mpVerWEB, mpCodigo"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "MateriaPrima"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('MateriasPrimas', JSON.stringify(rta))

                                if (document.getElementById('MateriasPrimas'))
                                {
                                    document.getElementById('MateriasPrimas').classList.add("fondo-sp")
                                    document.getElementById('MateriasPrimas').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('MateriasPrimas', "[]")

                                if (document.getElementById('MateriasPrimas'))
                                {
                                    document.getElementById('MateriasPrimas').classList.add("fondo-sp")
                                    document.getElementById('MateriasPrimas').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["MateriasPrimas"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["MateriasPrimas"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("FormasPago"))
                {
                    // Busca en la base de datos, todas las formas de pago -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idFormaPago"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "fpDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "fpValorMoneda, fpSigno, fpBanco, fpCaja, fpTarjeta, fpVirtual, fpCheque, fpPorcentajeTarj, fpVerWEB, fpTipo, fpPredet, fpCtaCte, idFormaPagoCaja, fpNoMostrarSaldoInicial"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "FormasPago"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Monedas', JSON.stringify(rta))

                                if (document.getElementById('Monedas'))
                                {
                                    document.getElementById('Monedas').classList.add("fondo-sp")
                                    document.getElementById('Monedas').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Monedas', "[]")

                                if (document.getElementById('Monedas'))
                                {
                                    document.getElementById('Monedas').classList.add("fondo-sp")
                                    document.getElementById('Monedas').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["FormasPago"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["FormasPago"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Unidades"))
                {
                    // Busca en la base de datos, todas las unidades de medida -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idUnidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "uniDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "uniAbrev"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Unidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Unidades', JSON.stringify(rta))

                                if (document.getElementById('Unidades'))
                                {
                                    document.getElementById('Unidades').classList.add("fondo-sp")
                                    document.getElementById('Unidades').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Unidades', "[]")

                                if (document.getElementById('Unidades'))
                                {
                                    document.getElementById('Unidades').classList.add("fondo-sp")
                                    document.getElementById('Unidades').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Unidades"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Unidades"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Depositos"))
                {
                    // Busca en la base de datos, todos los depósitos -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "entDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "entCodigo"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Entidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "entVigente = 1 AND idTipoEntidad in (SELECT idTipoEntidad FROM TiposEntidades where teDeposito = 1)"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Depositos', JSON.stringify(rta))

                                if (document.getElementById('Depositos'))
                                {
                                    document.getElementById('Depositos').classList.add("fondo-sp")
                                    document.getElementById('Depositos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Depositos', "[]")

                                if (document.getElementById('Depositos'))
                                {
                                    document.getElementById('Depositos').classList.add("fondo-sp")
                                    document.getElementById('Depositos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Depositos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Depositos"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Proveedores"))
                {
                    // Busca en la base de datos, todos los proveedores -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "entDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "entCodigo"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Entidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "entVigente = 1 AND idTipoEntidad in (SELECT idTipoEntidad FROM TiposEntidades where teProveedor = 1)"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Proveedores', JSON.stringify(rta))

                                if (document.getElementById('Proveedores'))
                                {
                                    document.getElementById('Proveedores').classList.add("fondo-sp")
                                    document.getElementById('Proveedores').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Proveedores', "[]")

                                if (document.getElementById('Proveedores'))
                                {
                                    document.getElementById('Proveedores').classList.add("fondo-sp")
                                    document.getElementById('Proveedores').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Proveedores"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Proveedores"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Transportes"))
                {
                    // Busca en la base de datos, todos los Transportes -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "entDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "entCodigo"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Entidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "entVigente = 1 AND idTipoEntidad in (SELECT idTipoEntidad FROM TiposEntidades where teTransporte = 1)"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Transportes', JSON.stringify(rta))

                                if (document.getElementById('Transportes'))
                                {
                                    document.getElementById('Transportes').classList.add("fondo-sp")
                                    document.getElementById('Transportes').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Transportes', "[]")

                                if (document.getElementById('Transportes'))
                                {
                                    document.getElementById('Transportes').classList.add("fondo-sp")
                                    document.getElementById('Transportes').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Trasportes"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Trasportes"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Sucursales"))
                {
                    // Busca en la base de datos, todas las sucursales -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idSucursal"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "sucDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "sucVerWEB"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Sucursales"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Sucursales', JSON.stringify(rta))

                                if (document.getElementById('Sucursales'))
                                {
                                    document.getElementById('Sucursales').classList.add("fondo-sp")
                                    document.getElementById('Sucursales').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Sucursales', "[]")

                                if (document.getElementById('Sucursales'))
                                {
                                    document.getElementById('Sucursales').classList.add("fondo-sp")
                                    document.getElementById('Sucursales').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Sucursales"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Sucursales"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Usuarios"))
                {
                    // Busca en la base de datos, todos los Usuarios -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idUsuario"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "usuNombre"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Usuarios"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "usuVigente = 1"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]
                                        
                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Usuarios', JSON.stringify(rta))

                                if (document.getElementById('Usuarios'))
                                {
                                    document.getElementById('Usuarios').classList.add("fondo-sp")
                                    document.getElementById('Usuarios').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Usuarios', "[]")

                                if (document.getElementById('Usuarios'))
                                {
                                    document.getElementById('Usuarios').classList.add("fondo-sp")
                                    document.getElementById('Usuarios').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Usuarios"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Usuarios"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposUsuarios"))
                {
                    // Busca en la base de datos, todos los Tipos de Usuarios -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoUsuario"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tuDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposUsuarios"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposUsuarios', JSON.stringify(rta))

                                if (document.getElementById('TiposUsuarios'))
                                {
                                    document.getElementById('TiposUsuarios').classList.add("fondo-sp")
                                    document.getElementById('TiposUsuarios').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposUsuarios', "[]")

                                if (document.getElementById('TiposUsuarios'))
                                {
                                    document.getElementById('TiposUsuarios').classList.add("fondo-sp")
                                    document.getElementById('TiposUsuarios').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposUsuarios"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposUsuarios"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposComprobantes"))
                {
                    // Busca en la base de datos, todos los Tipos de comprobante -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoComprobante"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tcDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "tcDH, tcDHStock, tcAlta, tcAjuste, tcRemito, tcFactura, tcPedido, tcRecibi, tcNotaCredito, tcNotaDebito, tcPredet, tcPedidoSucursal, " +
                                                "tcLetra, tcFiscal, tcEgreso, tcIngreso, tcReciboHaberes, tcCantCopias, tcTicketFactura, tcFacturacionElectronica, tcCodigoFAE, tcDescripFAE, " +
                                                "tcSoloLectura, tcReporte, tcVerWEB, tcDiscriminaIVA, tcObservDefa, tcContabilidad, idCuentaOtros, tcNotaCreditoCompra, tcNotaDebitoCompra, " +
                                                "tcPresupuesto, tcRemitoCompra, tcOrdenCompra, tcOrdenPago, tcAccionWEB, tcModalidadVenta"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposComprobantes"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposComprobantes', JSON.stringify(rta))

                                if (document.getElementById('TiposComprobantes'))
                                {
                                    document.getElementById('TiposComprobantes').classList.add("fondo-sp")
                                    document.getElementById('TiposComprobantes').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposComprobantes', "[]")

                                if (document.getElementById('TiposComprobantes'))
                                {
                                    document.getElementById('TiposComprobantes').classList.add("fondo-sp")
                                    document.getElementById('TiposComprobantes').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposComprobantes"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposComprobantes"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Configuraciones"))
                {
                    // saca la configuracion horizontal, ya que se re-busca todo de nuevo
                    spg.GuardarDato("ConfiguracionesHoriz", [])
                    
                    // Busca en la base de datos, todas las configuraciones -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "descrip", 
                                            valor: "NULL"
                                        },
                                        {
                                            nombre: "grupo", 
                                            valor: "NULL"
                                        }]

                    spg.LeerAPI("base/", "ver-configuraciones", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Configuraciones', JSON.stringify(rta))

                                if (document.getElementById('Configuraciones'))
                                {
                                    document.getElementById('Configuraciones').classList.add("fondo-sp")
                                    document.getElementById('Configuraciones').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Configuraciones', "[]")

                                if (document.getElementById('Configuraciones'))
                                {
                                    document.getElementById('Configuraciones').classList.add("fondo-sp")
                                    document.getElementById('Configuraciones').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Configuraciones"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Configuraciones"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("PuntosVenta"))
                {
                    // Busca en la base de datos, todos los Puntos de Venta -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idPuntoVenta"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "pvDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "pvNumero, pvVerWEB, idSucursal"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "PuntosVenta"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('PuntosVenta', JSON.stringify(rta))

                                if (document.getElementById('PuntosVenta'))
                                {
                                    document.getElementById('PuntosVenta').classList.add("fondo-sp")
                                    document.getElementById('PuntosVenta').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('PuntosVenta', "[]")

                                if (document.getElementById('PuntosVenta'))
                                {
                                    document.getElementById('PuntosVenta').classList.add("fondo-sp")
                                    document.getElementById('PuntosVenta').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["PuntosVenta"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["PuntosVenta"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposDocumentos"))
                {
                    // Busca en la base de datos, todos los Tipos de Documento -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoDocumento"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tdDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "tdCodigoFAE"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposDocumentos"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposDocumentos', JSON.stringify(rta))

                                if (document.getElementById('TiposDocumentos'))
                                {
                                    document.getElementById('TiposDocumentos').classList.add("fondo-sp")
                                    document.getElementById('TiposDocumentos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposDocumentos', "[]")

                                if (document.getElementById('TiposDocumentos'))
                                {
                                    document.getElementById('TiposDocumentos').classList.add("fondo-sp")
                                    document.getElementById('TiposDocumentos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposDocumentos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposDocumentos"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposContribuyentes"))
                {
                    // Busca en la base de datos, todos los Tipos de Contribuyentes -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoContribuyente"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tyDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "tyAbrev, tyCodigoFAE"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposContribuyentes"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposContribuyentes', JSON.stringify(rta))

                                if (document.getElementById('TiposContribuyentes'))
                                {
                                    document.getElementById('TiposContribuyentes').classList.add("fondo-sp")
                                    document.getElementById('TiposContribuyentes').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposContribuyentes', "[]")

                                if (document.getElementById('TiposContribuyentes'))
                                {
                                    document.getElementById('TiposContribuyentes').classList.add("fondo-sp")
                                    document.getElementById('TiposContribuyentes').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposContribuyentes"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposContribuyentes"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposEntidades"))
                {
                    // Busca en la base de datos, todos los Tipos de Entidad -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "teDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "teCliente, teProveedor, teDeposito, teTaller, teTallerista, teSindicato, teObraSocial, teEmpleado, teSUSS, teBanco, teTransporte, teSubCliente, teOtros1, teOtros2, teOtros3, teOtros4, idCuenta, idCuentaCtaCTe, tePorcentaje, teNewsLetter"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposEntidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposEntidades', JSON.stringify(rta))

                                if (document.getElementById('TiposEntidades'))
                                {
                                    document.getElementById('TiposEntidades').classList.add("fondo-sp")
                                    document.getElementById('TiposEntidades').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposEntidades', "[]")

                                if (document.getElementById('TiposEntidades'))
                                {
                                    document.getElementById('TiposEntidades').classList.add("fondo-sp")
                                    document.getElementById('TiposEntidades').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposEntidades"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposEntidades"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposCuentasBancarias"))
                {
                    // Busca en la base de datos, todos los Tipos de Contribuyentes -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoCuentaBancaria"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "tcbDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposCuentasBancarias"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposCuentasBancarias', JSON.stringify(rta))

                                if (document.getElementById('TiposCuentasBancarias'))
                                {
                                    document.getElementById('TiposCuentasBancarias').classList.add("fondo-sp")
                                    document.getElementById('TiposCuentasBancarias').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposCuentasBancarias', "[]")

                                if (document.getElementById('TiposCuentasBancarias'))
                                {
                                    document.getElementById('TiposCuentasBancarias').classList.add("fondo-sp")
                                    document.getElementById('TiposCuentasBancarias').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposCuentasBancarias"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposCuentasBancarias"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Bancos"))
                {
                    // Busca en la base de datos, todos los Bancos -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "entDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Entidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "idTipoEntidad in (SELECT idTipoEntidad FROM TiposEntidades where teBanco = 1)"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Bancos', JSON.stringify(rta))

                                if (document.getElementById('Bancos'))
                                {
                                    document.getElementById('Bancos').classList.add("fondo-sp")
                                    document.getElementById('Bancos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Bancos', "[]")

                                if (document.getElementById('Bancos'))
                                {
                                    document.getElementById('Bancos').classList.add("fondo-sp")
                                    document.getElementById('Bancos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Bancos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Bancos"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("PaquetesEnvios"))
                {
                    // Busca en la base de datos, todos los Paquetes para Envios -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idPaqueteEnvio"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "peDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "PaquetesEnvios"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('PaquetesEnvios', JSON.stringify(rta))

                                if (document.getElementById('PaquetesEnvios'))
                                {
                                    document.getElementById('PaquetesEnvios').classList.add("fondo-sp")
                                    document.getElementById('PaquetesEnvios').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('PaquetesEnvios', "[]")

                                if (document.getElementById('PaquetesEnvios'))
                                {
                                    document.getElementById('PaquetesEnvios').classList.add("fondo-sp")
                                    document.getElementById('PaquetesEnvios').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["PaquetesEnvios"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["PaquetesEnvios"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Provincias"))
                {
                    // Busca en la base de datos, todos las Provincias -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idProvincia"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "proDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Provincias"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Provincias', JSON.stringify(rta))

                                if (document.getElementById('Provincias'))
                                {
                                    document.getElementById('Provincias').classList.add("fondo-sp")
                                    document.getElementById('Provincias').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Provincias', "[]")

                                if (document.getElementById('Provincias'))
                                {
                                    document.getElementById('Provincias').classList.add("fondo-sp")
                                    document.getElementById('Provincias').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Provincias"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Provincias"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("Financiaciones"))
                {
                    // Busca en la base de datos, todos los tipos de Financiacion -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idFinanciacion"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "finDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "finTipo, finCantCuotas, finPlazo, finIntereses, finPorcentaje, finPredet"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Financiaciones"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('Financiaciones', JSON.stringify(rta))

                                if (document.getElementById('Financiaciones'))
                                {
                                    document.getElementById('Financiaciones').classList.add("fondo-sp")
                                    document.getElementById('Financiaciones').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('Financiaciones', "[]")

                                if (document.getElementById('Financiaciones'))
                                {
                                    document.getElementById('Financiaciones').classList.add("fondo-sp")
                                    document.getElementById('Financiaciones').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["Financiaciones"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["Financiaciones"])

                        reject(rta)
                    })
                }
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("TiposPeriodos"))
                {
                    // Busca en la base de datos, todos los tipos de Periodo de facturación -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idTipoPeriodo"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "perDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "perCodigo, perDias, perPredet"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "TiposPeriodos"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('TiposPeriodos', JSON.stringify(rta))

                                if (document.getElementById('TiposPeriodos'))
                                {
                                    document.getElementById('TiposPeriodos').classList.add("fondo-sp")
                                    document.getElementById('TiposPeriodos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('TiposPeriodos', "[]")

                                if (document.getElementById('TiposPeriodos'))
                                {
                                    document.getElementById('TiposPeriodos').classList.add("fondo-sp")
                                    document.getElementById('TiposPeriodos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["TiposPeriodos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposPeriodos"])

                        reject(rta)
                    })
                } 
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("ClientePredeterminado"))
                {
                    // Busca en la base de datos, todos los tipos de Periodo de facturación -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idEntidad"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "entDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "Entidades"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: "entPredet = 1 AND idTipoEntidad IN (SELECT idTipoEntidad FROM TiposEntidades where teCliente = 1)"
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('ClientePredeterminado', JSON.stringify(rta))

                                if (document.getElementById('ClientePredeterminado'))
                                {
                                    document.getElementById('ClientePredeterminado').classList.add("fondo-sp")
                                    document.getElementById('ClientePredeterminado').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('ClientePredeterminado', "[]")

                                if (document.getElementById('ClientePredeterminado'))
                                {
                                    document.getElementById('ClientePredeterminado').classList.add("fondo-sp")
                                    document.getElementById('ClientePredeterminado').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["ClientePredeterminado"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["ClientePredeterminado"])

                        reject(rta)
                    })
                } 
                    
                if (/*aTablas.length === 0 ||*/ aTablas.includes("ImpuestosInternos"))
                {
                    // Busca en la base de datos, todos los tipos de Periodo de facturación -----------------------------------------------------------------
                    let aParametros = [{
                                            nombre: "campoid", 
                                            valor: "idImpuestoInterno"
                                        },
                                        {
                                            nombre: "campodescrip", 
                                            valor: "iiDescrip"
                                        },
                                        {
                                            nombre: "otroscampos", 
                                            valor: "iiPorcentaje"
                                        },
                                        {
                                            nombre: "campopredet", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "tabla", 
                                            valor: "ImpuestosInternos"
                                        },
                                        {
                                            nombre: "valor", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "otracondicion", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "filtroigual", 
                                            valor: "0"
                                        },
                                        {
                                            nombre: "camposfiltro", 
                                            valor: ""
                                        },
                                        {
                                            nombre: "traertodos", 
                                            valor: "1"
                                        }]

                    spg.LeerAPI("base/", "select", aParametros).then((rta) => 
                    {
                        switch (rta.status)
                        {
                            case 200:
                                AsyncStorage.setItem('ImpuestosInternos', JSON.stringify(rta))

                                if (document.getElementById('ImpuestosInternos'))
                                {
                                    document.getElementById('ImpuestosInternos').classList.add("fondo-sp")
                                    document.getElementById('ImpuestosInternos').classList.remove("falta")
                                }

                                resolve(rta)

                                break 
                            case 409:
                                AsyncStorage.setItem('ImpuestosInternos', "[]")

                                if (document.getElementById('ImpuestosInternos'))
                                {
                                    document.getElementById('ImpuestosInternos').classList.add("fondo-sp")
                                    document.getElementById('ImpuestosInternos').classList.remove("falta")
                                }

                                resolve(rta)

                                break
                            default:
                                // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                                this.BuscarDatosBase(["ImpuestosInternos"])

                                reject(rta)
                        }
                    }).catch((rta) => 
                    {
                        // Si da error, busca nuevamente, es porque no pudo encontrar, o hubo algun tipo de error en sql o la API
                        this.BuscarDatosBase(["TiposPeriodos"])

                        reject(rta)
                    })
                } 
            }
        })
    }
    
    /** 
     * Elimina los datos base/maestros de la base de datos
     * Tipos subtipos de producto, materias primas, tipos de comprobante, tipos de entidades etc
    */
    EliminarDatosBase(cTabla, cCampoID, cValor, oConfirmacion)
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()

            // Busca en la base de datos, todos los tipos de producto
            let aParametros = [{
                                    nombre: "campoid", 
                                    valor: cCampoID
                                },
                                {
                                    nombre: "tabla", 
                                    valor: cTabla
                                },
                                {
                                    nombre: "valor", 
                                    valor: cValor
                                }]
            
            //console.log(aParametros)

            spg.LeerAPI("base/", "delete", aParametros)
                .then(function(value) 
                {
                    if (oConfirmacion)
                    {
                        oConfirmacion.ConfimarEliminacion(value)
                    }

                    resolve(value)
                }, 
                function(reason) 
                {
                    if (oConfirmacion)
                    {
                        oConfirmacion.ConfimarEliminacion(reason)
                    }

                    reject(reason)
                });
        })
    }

    /** 
     * Actualiza los datos base/maestros de la base de datos
     * Tipos subtipos de producto, materias primas, tipos de comprobante, tipos de entidades etc 
    */
    UpdateDatosBase(cTabla, cCampoID, cValores, oConfirmacion)
    {
        const spg = this
        
        return new Promise(function(resolve, reject) 
        {
            // Busca en la base de datos, todos los tipos de producto
            const aParametros = [{
                                    nombre: "campoid", 
                                    valor: cCampoID
                                },
                                {
                                    nombre: "valores", 
                                    valor: cValores
                                },
                                {
                                    nombre: "tabla", 
                                    valor: cTabla 
                                }]
            
            spg.LeerAPI("base/", "update", aParametros)
                .then(function(value) 
                {
                    resolve(true)

                    if (oConfirmacion)
                    {
                        oConfirmacion.ConfimarUpdate(value, cTabla)
                    }
                }, 
                function(reason) 
                {
                    reject()

                    if (oConfirmacion)
                    {
                        oConfirmacion.ConfimarUpdate(reason)
                    }
                });
        })
    }

    /** 
     * Selecciona los datos base/maestros de la base de datos
     * Tipos subtipos de producto, materias primas, tipos de comprobante, tipos de entidades etc 
    */
    SelectDatosBase(nID, nDescrip, nOtros, nCamposFiltro, nTabla, nValor = "", nOtraCondicion = "", nCampoPredet = "", nFiltroIgual = "", nOrden = "", nTraerTodos = "")
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()
            const aParametros = [
                                {
                                    nombre: "campoid",
                                    valor: nID
                                },
                                {
                                    nombre: "campodescrip",
                                    valor: nDescrip
                                },
                                {
                                    nombre: "otroscampos",
                                    valor: nOtros
                                },
                                {
                                    nombre: "campopredet",
                                    valor: nCampoPredet
                                },
                                {
                                    nombre: "tabla",
                                    valor: nTabla
                                },
                                {
                                    nombre: "valor",
                                    valor: nValor
                                },
                                {
                                    nombre: "otracondicion",
                                    valor: nOtraCondicion
                                },
                                {
                                    nombre: "filtroigual",
                                    valor: nFiltroIgual
                                },
                                {
                                    nombre: "camposfiltro",
                                    valor: nCamposFiltro
                                },
                                {
                                    nombre: "orden",
                                    valor: nOrden
                                },
                                {
                                    nombre: "traertodos",
                                    valor: nTraerTodos ? nTraerTodos : "0"
                                }]
            
            spg.LeerAPI("base/", "select", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve(jRta) 
                }
                else
                {
                    reject(jRta)
                }
            })
        })
    }

    /** 
     * Inserta los datos base/maestros de la base de datos
     * Tipos subtipos de producto, materias primas, tipos de comprobante, tipos de entidades etc 
    */
    InsertDatosBase(cTabla, cCampos, cValores)
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()

            // Busca en la base de datos, todos los tipos de producto
            const aParametros = [{
                                    nombre: "campos", 
                                    valor: cCampos
                                },
                                {
                                    nombre: "valores", 
                                    valor: cValores
                                },
                                {
                                    nombre: "tabla", 
                                    valor: cTabla 
                                }]
            
            spg.LeerAPI("base/", "insert", aParametros).then((jRta) =>
            {
                if (jRta.status === 200)
                {
                    resolve(jRta)
                }
                else
                {
                    reject(jRta)
                }
            })
        })
    }

    /**
     * ## PadLeft
     * 
     * ### Método que te permite rellenar un número o cadena de texto con 0 (ceros) o el caracter indicado a la izquierda
     * 
     * @param {*} value es el valor, número, cadena de texto que desea rellenar
     * @param {int} length es la cantidad total de caracteres que desea que se devuelva
     * @returns Devuelve un string con un largo total segun el parámetro "length" con la cantidad de ceros resultante de la resta "length - value.lenght" es decir, si se envía esta instrución: PadLeft(54, 8), 
     * devuelve 00000054 ya que rellena hasta 8 caracteres el numero 54
     */
    PadLeft(value, length, cCharReemplazar = "0") 
    {
        return (value.toString().length < length) ? this.PadLeft(cCharReemplazar + value, length) : value;
    }

    /**
     * ## PadRight
     * 
     * ### Método que te permite rellenar un número o cadena de texto con 0 (ceros) o el caracter indicado a la izquierda
     * 
     * @param {*} value es el valor, número, cadena de texto que desea rellenar
     * @param {int} length es la cantidad total de caracteres que desea que se devuelva
     * @returns Devuelve un string con un largo total segun el parámetro "length" con la cantidad de ceros resultante de la resta "length - value.lenght" es decir, si se envía esta instrución: PadRight(54, 8), 
     * devuelve 54000000 ya que rellena hasta 8 caracteres el numero 54
     */
    PadRight(value, length, cCharReemplazar = " ") 
    {
        return (value.toString().length < length) ? this.PadRight(value + cCharReemplazar, length) : value;
    }

    /**
     * ## getParameterByName
     * 
     * ### Método que permite consultar el valor de un parámetro en la URL actual
     * 
     * 
     * @param {string} cParamName Nombre del parámetro a buscar
     * @returns string con el valor del parámetro buscado, o string vacío en caso de no existir
     */
    getParameterByName(cParamName)
    {
        // separa la parte de servidore/directorios de la parte de los parametros
        const urlElements = window.location.href.split('?');
        let cValorRetorno = ""

        if (urlElements[1])
        {
            const cParametros = urlElements[1]
            
            // separa de la parte de lo sparametros, cada parametro
            const aParametros = cParametros.split("&")
            
            for (let i = 0; i < aParametros.length; i++)
            {
                const aParamValor = aParametros[i].split("=")
                
                if (aParamValor[0].toLowerCase() === cParamName.toLowerCase())
                {
                    cValorRetorno = aParamValor[1]
                }
            }
        }

        return cValorRetorno
    }

    /**
     * ## Ceiling
     * ### Es un metodo que te permite redondear un numero cada cierto período 
     * 
     * Por ej: si el número enviado en el parametro "nNumero" es 5.51 y el parámetro "nRedondear" es 1, devuelve un número redondeado al más cercano (SIEMPRE PARA ARRIBA), 
     * es decir que devolvería 6, si el parámetro "nRedondear" viniera en 5, devolvería 10, ya que el más cercano múltiplo de 5 es 10
     * 
     * Si el número enviado en el parametro "nNumero" es 54.50 y el parámetro "nRedondear" es 50, devuelve 50, que se redondea cada 50
     * 
     * Siempre redondea los números CADA nRedondear
     * 
     * NOTA: Para no redondear, envíe 0.01 o 0, de esta forma se redondeará siempre cada 0.01, es decir, no se redondea, ya que el rango normal es ese, todos los numeros float, se manejan cada 0.01
     * 
     * @param {float} nNumero Número a redondear
     * @param {float} nRedondear rango o múltiplo a redondear
     * @returns float
     */
    Ceiling(nNumero, nRedondear)
    {
        if (!nRedondear)
        {
            nRedondear = 0.01
        } 

        return Math.ceil(nNumero / nRedondear) * nRedondear
    }

    /**
     * ## ControlarCampoUnico
     * ### Es un método que permite controlar que un campo no se repita
     * 
     * @param {string} cTabla Nombre de la tabla a controlar
     * @param {string} cCampoControlar Nombre del campo a controlar la unicidad
     * @param {string} cValor valor del código actual que intenta poner para controlar
     * @param {string} cCampoID nombre del campo ID
     * @param {string} nIDRegistro ID del registro actual que está poniendo el código
     * @param {string} cCampoDescrip nombre del campo descrip, para mostrar cual es el registro que tiene el mismo código que está insertando
     * @param {string} cCampoVigente nombre del campo vigente, para revisar sólo en los vigentes
     * @param {string} cOtroCampo otro campo a mostrar
     * @returns PROMISE 
     * 
     *      si se repite el codigo, manda un reject con un string con la descripcion del registro que tiene el código duplicado
     *      si no se repite, manda un resolve con un string vacio
     *      si da error, manda un reject con un error
     */
    ControlarCampoUnico(cTabla = "", cCampoControlar = "", cValor = "", cCampoID = "", nIDRegistro = 0, cCampoDescrip = "", cCampoVigente = "", cOtroCampo = "")
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG();
            const aParametros = [
                                {
                                    nombre: "Tabla",
                                    valor: cTabla
                                },
                                {
                                    nombre: "Campo",
                                    valor: cCampoControlar
                                },
                                {
                                    nombre: "Valor",
                                    valor: cValor
                                },
                                {
                                    nombre: "id",
                                    valor: cCampoID
                                },
                                {
                                    nombre: "Descrip",
                                    valor: cCampoDescrip
                                },
                                {
                                    nombre: "CampoVigente",
                                    valor: cCampoVigente
                                },
                                {
                                    nombre: "OtroCampo",
                                    valor: cOtroCampo
                                }]
            
            spg.LeerAPI("base/", "controlar-campo-unico", aParametros).then((jRta) => {
                if (jRta.status === 200)
                {
                    if (parseInt(jRta[0].idProducto) !== parseInt(nIDRegistro))
                    {
                        reject(jRta[0][cCampoDescrip])
                    }
                    else
                    {
                        resolve("")
                    }
                }
                else
                {
                    if (jRta.status === 409)
                    {
                        resolve("")
                    }
                    else 
                    {
                        reject("No identificado (hubo un error)")
                    }
                }
            })
        })
    }

    /**
     * ## Limpiar Tabla WEB
     * 
     * ### Método para limpiar la tabla intermedia de productos web
     */
    LimpiarTablaWEB(nTipoProducto = "NULL", nSubTipoProducto = "NULL", bLimpiarTodo = 0, bLimpiarStkEmp = 0)
    {
        const spg = new DatosSPG()
        const aParametros = [{
                                nombre: "idTipoProducto",
                                valor: nTipoProducto
                            },
                            {
                                nombre: "idSubTipoProducto",
                                valor: nSubTipoProducto
                            },
                            {
                                nombre: "LimpiarTodo",
                                valor: bLimpiarTodo
                            },
                            {
                                nombre: "LimpiarStkEmp",
                                valor: bLimpiarStkEmp
                            }]
        
        return new Promise(function(resolve, reject) 
        {
            spg.LeerAPI("productos/", "update-productos-web", aParametros).then((jRta) => 
            {
                if (jRta.status === 200 || jRta.status === 409)
                {
                    resolve(jRta)
                }
                else
                {
                    reject(jRta)
                }
            }).catch((jRta) => reject(jRta))
        })
    }

    /**
     * ## CambiarNroBase
     * 
     * ### Metodo para cambiar un número de base, util para leer el formato de un código 36AP
     * 
     * @param {string} cNro 
     * @param {int} nBOrigen 
     * @param {int} nBDestino 
     */
    CambiarNroBase(cNro, nBOrigen, nBDestino)
    {
        let nValor = 0

        cNro = cNro.toString()

        if (nBDestino === 10) // Base x a base 10
        {
            let nPotencia = 0
            
            nValor = 0
            nPotencia = 0 
            
            if (cNro > nBDestino.toString() || cNro.length > nBDestino.toString().length)
            {
                // Separa el numero en caracteres
                const aNumeroTransf = cNro.split("")
                const aNumeros = ("0123456789").split("")
                
                for (let i = aNumeroTransf.length - 1; i >= 0; i--)
                {
                    const nValCaracter = (aNumeros.includes(aNumeroTransf[i]) 
                                            ? aNumeroTransf[i]
                                            : aNumeroTransf[i].charCodeAt(0) - 55)
                    
                    nValor += (parseInt(nValCaracter) * Math.pow(nBOrigen, nPotencia))

                    nPotencia++
                }
            }
            else
            {
                nValor = parseInt(cNro)
            } 
        } 
        
        return nValor 
    }

    /**
     * ## Método BuscarFechaServidor
     * 
     * ### Método para buscar una fecha del servidor
     * 
     * Normalmente utilizado para sincronizar fechas, y controlar que se esté haciendo un movimiento exactamente en la fecha/hora que se desea
     * 
     * @param {string} cTipo es el parametro que define el tipo de fecha que va a buscar, por default, busca la fecha del servidor
     * @returns valor fecha, la fecha del servidor requerida
     */
    BuscarFechaServidor(cTipo = "SERVIDOR")
    { 
		const spg = new DatosSPG();
		const aParametros = [
							{
								nombre: "Tipo",
								valor: cTipo ? cTipo : "SERVIDOR"
							}]
		 
        return new Promise(function(resolve, reject) 
        {
            spg.LeerAPI("consultas/", "ver-fecha-servidor", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve(jRta[0].Fecha)
                }
                else
                {
                    reject(false)
                }
            }).catch(() => reject(false))
        })
    }

    /**
     * ## Método BuscarClienteAFIP 
     * 
     * ### Método para buscar los datos de un cliente a partir del CUIT
     * 
     * Esta búsqueda la realiza a través de la API de AFIP buscando en su base de datos los que nos hacen falta a nosotros para crear un cliente
     * 
     * @param {string} cCUIT debe enviar el CUIT que está tratando de encontrar (puede ser numérico o con guiones)
     * @returns Un array vacío, si no se encuentra, o un array con los datos del cliente como se muestra a continuación: 
     * 
     * { 
     * 
     *      nombre: 'xxxxx', 
     * 
     *      calle: 'xxxxxx', 
     * 
     *      numero: 1234, 
     * 
     *      piso: 'xx', 
     * 
     *      depto: 'xx', 
     * 
     *      cp: 1234, 
     * 
     *      barrio: 'xxxx', 
     * 
     *      localidad: 'xxx', 
     * 
     *      provincia: 'xxxxx', 
     * 
     *      id: 123456789 
     * 
     *      }
     */
    BuscarClienteAFIP(cCUIT)
    {
        return new Promise(function(resolve, reject) 
        {
            const spg = new DatosSPG()
            
            spg.LlamarURL(spg.Server() + "afip/controles.php?c=CUIT&valor=" + cCUIT + "&rnd=" + (Math.floor(Math.random() * 100000000)).toFixed(), [], 0).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve(jRta.data)
                }
                else
                {
                    reject([])
                }
            }).catch(() => reject(false))
        })
    }
}

export default DatosSPG;