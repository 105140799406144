import DatosSPG from "../componentes/funciones"

/**
 * ## Clase Permisos
 * 
 * ### Permite manejar todo lo relacionado a permisos y seguridad
 */
class Permisos 
{
    /**
     * ## Ver Permisos
     * 
     * ### Método Ver permisos, se utiliza para ver los permisos de acceso, alta, baja, etc, para el usuario logueado para un módulo específico
     * 
     * Envíe el nobre, url y la conformación de permisos de ese módulo y se le devolverá un array con los permisos para dicho módulo
     * 
     * @param {string} cModulo 
     * @param {string} cURL 
     * @param {boolean} bAlta 
     * @param {boolean} bBaja 
     * @param {boolean} bModificacion 
     * @param {boolean} bConsulta 
     * @param {boolean} bImpresion 
     * @param {boolean} nModuloPadre 
     * @param {boolean} bModuloWEB 
     * @returns array
     */
    VerPermisos(cModulo, cURL = "/", bAlta = "NULL", bBaja = "NULL", bModificacion = "NULL", bConsulta = "NULL", bImpresion = "NULL", nModuloPadre = "NULL", bModuloWEB = true)
    {
        return new Promise(function(resolve, reject) 
        { 
            const spg = new DatosSPG()
            const nUsuario = spg.VerDato("idUsuario")
            const bAdmin = spg.VerDato("usuAdmin") && spg.VerDato("usuAdmin") === "1" ? true : false
            const bSeguridad = spg.VerDato("bdSeguridad") ? true : false
            
            if (bSeguridad && !bAdmin)
            {
                const aParametros = [
                                        {
                                            nombre: "idUsuario",
                                            valor: nUsuario
                                        },
                                        {
                                            nombre: "modDescrip",
                                            valor: cModulo
                                        },
                                        {
                                            nombre: "idPadre",
                                            valor: nModuloPadre
                                        },
                                        {
                                            nombre: "a",
                                            valor: bAlta
                                        },
                                        {
                                            nombre: "b",
                                            valor: bBaja
                                        },
                                        {
                                            nombre: "m",
                                            valor: bModificacion
                                        },
                                        {
                                            nombre: "c",
                                            valor: bConsulta
                                        },
                                        {
                                            nombre: "i",
                                            valor: bImpresion
                                        },
                                        {
                                            nombre: "modURL",
                                            valor: cURL
                                        },
                                        {
                                            nombre: "modWEB",
                                            valor: bModuloWEB ? 1 : 0
                                        }
                                    ]
                
                spg.LeerAPI("seguridad/", "ver-permisos", aParametros).then((jRta) => 
                {
                    jRta = jRta[0]
                    
                    // Agrega el campo seguridad al array, sólo para poder visualziarlo
                    Object.defineProperty(jRta, "seguridad", { value: true, "writable": true })
                    
                    if (jRta.status === 200 || typeof jRta.Acceso !== "undefined")
                    {
                        resolve(jRta)
                    }
                    else
                    {
                        // Agregar Tratamiento por error, en caso que sea 409 es porque la consulta vino vacía
                        if (jRta.status !== 409)
                        {
                            reject(jRta)
                        }
                    }
                }).catch(() => reject({ status: 404, seguridad: bSeguridad, Acceso: false, Alta: false, Baja: false, Modificacion: false, Consulta: false, Impresion: false, idModulo: null, admin: bAdmin }))
            }
            else
            {
                resolve({ status: 200, seguridad: bSeguridad, Acceso: true, Alta: true, Baja: true, Modificacion: true, Consulta: true, Impresion: true, idModulo: null, admin: bAdmin })
            }
        }) 
    }

    /**
     * ## VerPartes del Sistema
     * 
     * ### Método para ver si tiene permisos para entrar en la parte del sistema que viene por parametro
     * 
     * Este permiso o denegación, depende de la licencia que tenga comprada el cliente
     * 
     * Si aunque sea una de las partes enviadas, tiene permiso, devuelve true, si ninguna tiene permiso, se envía false. 
     * Esto Funciona asi ya que está pensado para que una pantalla pueda pertenecer a varias partes del sistema, por ej: la pantalla de Productos puede usarse en el subsistema de stock
     * pero también puede usarse en el subsistema de órdenes de trabajo, y si tiene permiso para usar stock, entonces puede usar a pantalla de productos, pero si tiene permiso
     * para Órdenes de Trabajo, puede también usar la pantalla de productos 
     * 
     * @param {string} cPartes indica las partes del sistema que está invcando, para saber si tiene permisos o no a dichas partes, string separando por comas, cada parte que puede pertenecer la pantalla que está abriendo
     * @param {boolean} bLimpiarCache indica si se limpia la cache, es porque suele ser un cambio 
     * 
     * @returns true/false si tiene o no permiso
     * 
     * Ej: 
     * VerPermiso()
     * 
     * {
     * 
     * const spg = new DatosSPG()
     * 
     * spg.VerParteSistema("Stock, Web Avanzado, Financiero").then((rta) => this.setState({ estado: rta }))
     * 
     * }
     */
    VerParteSistema(aPartes = [ "Sistema Básico" ], bLimpiarCache = false)
    { 
        return new Promise(function(resolve, reject) 
        {
            if (aPartes.length === 0)
            {
                aPartes = [ "Sistema Básico" ]
            }
            
            const spg = new DatosSPG();
            const aParametros = [{
                                    nombre: "PartesSistema",
                                    valor: aPartes.length > 0 ? aPartes.toString() : "Sistema Básico"
                                },
                                {
                                    nombre: "idBD",
                                    valor: spg.VerDato("idBD")
                                },
                                {
                                    nombre: "limpiarcache",
                                    valor: bLimpiarCache ? "1" : "0"
                                }]
            
            spg.LeerAPI("clientes-softplace/", "ver-partes-sistema", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    let nPartesPermitidas = 0

                    for (let i = 0; i < jRta.length; i++)
                    { 
                        if (jRta[i].psPermitido && aPartes.includes(jRta[i].psNombre.trim()))
                        {
                            nPartesPermitidas++ 
                        }
                    } 

                    resolve(nPartesPermitidas > 0 ? true : false)
                }
                else
                {
                    reject(false)
                }
            }).catch(() => reject(false))
        }) 
    }
}

export default Permisos;