import React from 'react';
import { Line } from "react-chartjs-2"

import { Chart as ChartJS, 
         CategoryScale,
         LinearScale,
         PointElement,
         LineElement,
         Title,
         Tooltip,
         Legend,
         Filler } from "chart.js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale,
                 LinearScale,
                 PointElement,
                 LineElement,
                 Title,
                 Tooltip,
                 Legend,
                 Filler)

let opciones = { 
                    animation: {
                                   duration: 500
                               },
                    plugins: { 
                                 legend: { display: true }
                             } 
               } 

/**
 * ## CHARTLINES
 * 
 * ### Clase para crear componentes tipo chart con líneas
 * 
 * Tenga en cuenta que puede indicar más de un dataset, es decir, más de una línea en el mismo gráfico
 * 
 * @param {string} titulos es un array string, con los títulos de los diferentes datasets (hasta 10)
 * @param {string} datos es un array de los diferentes datasets (hasta 10)
 * @param {string} colores es un array string, con los colores a utilizar de los diferentes datasets (hasta 10)
 * @param {string} labels es un array string, con los todos los labels que puede haber, ejemplo, meses, días etc
 * @param {boolean} solografico es un bool que dice si se muestra sólo el grafico, sin las etiquetas ni valores
 * @param {boolean} fill es un bool que dice si se muestra rellenas las areas de las curvas debajo de cada linea
 * @param {boolean} sinanimation es un bool que indica si tiene no debe tener animación el grafico 
 * @param {number} tension es un numero que indica el grado de curvatura de las lineas
 * @param {number} pointradius es un numero que indica el radio de los puntos indicadores
 * @param {number} height es un numero que alto el ancho total del gráfico
 * @param {number} width es un numero que indica el ancho total del gráfico
 * @param {number} widthLine es un numero que indica el ancho de la linea en el grafico
 */
class ChartLines extends React.Component
{ 
    render()
    {
        const aDataSet = this.props.datos
        const aColorSet = this.props.colores
        const aTituloSet = this.props.titulos
        const nHeight = (this.props.height ? this.props.height : null)
        const nWidth = (this.props.width ? this.props.width : null)
        const bFill = (this.props.fill ? this.props.fill : true)
        const nTension = (this.props.tension ? this.props.tension : 0.5)

        let aDatos = []
        
        switch (aDataSet.length)
        {
            case 1:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill, 
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }]
                        }

                break
            case 2:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill, 
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }]
                        }

                break
            case 3:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }]
                        }

                break
            case 4:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }]
                        }

                break
            case 5:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }]
                        }

                break 
            case 6:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }]
                        }

                break
            case 7:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }, 
                                    {
                                        label: aTituloSet[6],
                                        data: aDataSet[6],
                                        tension: 0.6,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointBackgroundColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral")
                                    }]
                        }

                break
            case 8:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }, 
                                    {
                                        label: aTituloSet[6],
                                        data: aDataSet[6],
                                        tension: 0.6,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointBackgroundColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral")
                                    }, 
                                    {
                                        label: aTituloSet[7],
                                        data: aDataSet[7],
                                        tension: 0.7,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)")
                                    }]
                        }

                break
            case 9:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }, 
                                    {
                                        label: aTituloSet[6],
                                        data: aDataSet[6],
                                        tension: 0.6,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointBackgroundColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral")
                                    }, 
                                    {
                                        label: aTituloSet[7],
                                        data: aDataSet[7],
                                        tension: 0.7,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)")
                                    }, 
                                    {
                                        label: aTituloSet[8],
                                        data: aDataSet[8],
                                        tension: 0.8,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointBackgroundColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan")
                                    }]
                        }

                break
            case 10:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }, 
                                    {
                                        label: aTituloSet[6],
                                        data: aDataSet[6],
                                        tension: 0.6,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointBackgroundColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral")
                                    }, 
                                    {
                                        label: aTituloSet[7],
                                        data: aDataSet[7],
                                        tension: 0.7,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)")
                                    }, 
                                    {
                                        label: aTituloSet[8],
                                        data: aDataSet[8],
                                        tension: 0.8,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointBackgroundColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan")
                                    }, 
                                    {
                                        label: aTituloSet[9],
                                        data: aDataSet[9],
                                        tension: 0.9,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink"),
                                        pointBackgroundColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink")
                                    }]
                        }

                break
            default:
                aDatos = {
                            labels: this.props.labels,
                            datasets: [{
                                        label: aTituloSet[0],
                                        data: aDataSet[0],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2), 
                                        borderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange"),
                                        pointBackgroundColor: (aColorSet && aColorSet[0] ? aColorSet[0] : "orange")
                                    }, 
                                    {
                                        label: aTituloSet[1],
                                        data: aDataSet[1],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green"),
                                        pointBackgroundColor: (aColorSet && aColorSet[1] ? aColorSet[1] : "green")
                                    }, 
                                    {
                                        label: aTituloSet[2],
                                        data: aDataSet[2],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue"),
                                        pointBackgroundColor: (aColorSet && aColorSet[2] ? aColorSet[2] : "blue")
                                    }, 
                                    {
                                        label: aTituloSet[3],
                                        data: aDataSet[3],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[3] ? aColorSet[3] : "rgb(118, 7, 41)")
                                    }, 
                                    {
                                        label: aTituloSet[4],
                                        data: aDataSet[4],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet"),
                                        pointBackgroundColor: (aColorSet && aColorSet[4] ? aColorSet[4] : "violet")
                                    }, 
                                    {
                                        label: aTituloSet[5],
                                        data: aDataSet[5],
                                        tension: nTension,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[5] ? aColorSet[5] : "rgb(46, 64, 83)")
                                    }, 
                                    {
                                        label: aTituloSet[6],
                                        data: aDataSet[6],
                                        tension: 0.6,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral"),
                                        pointBackgroundColor: (aColorSet && aColorSet[6] ? aColorSet[6] : "coral")
                                    }, 
                                    {
                                        label: aTituloSet[7],
                                        data: aDataSet[7],
                                        tension: 0.7,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)"),
                                        pointBackgroundColor: (aColorSet && aColorSet[7] ? aColorSet[7] : "rgb(31, 195, 138)")
                                    }, 
                                    {
                                        label: aTituloSet[8],
                                        data: aDataSet[8],
                                        tension: 0.8,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan"),
                                        pointBackgroundColor: (aColorSet && aColorSet[8] ? aColorSet[8] : "cyan")
                                    }, 
                                    {
                                        label: aTituloSet[9],
                                        data: aDataSet[9],
                                        tension: 0.9,
                                        fill: bFill,
                                        borderWidth: (this.props.widthLine ? this.props.widthLine : 2),
                                        borderColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink"),
                                        pointRadius: (this.props.pointradius ? this.props.pointradius : 3),
                                        poinBorderColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink"),
                                        pointBackgroundColor: (aColorSet && aColorSet[9] ? aColorSet[9] : "pink")
                                    }]
                        }
        }
        
        if (aDataSet.length > 0)
            return(<div className='col-md-12'>
                       { !this.props.solografico
                          ? <div className='aclaraciones text-center'>
                                    <FontAwesomeIcon icon={ faInfoCircle } /> Haga click en las etiquetas para quitar datos que no desee ver
                            </div>
                          : []
                       }

                       <Line height={ nHeight } 
                             width={ nWidth } 
                             data={ aDatos } 
                             options={ opciones } />
                   </div>)
        else 
            return(<div className='d-flex align-items-center justify-content-center'>
                        <FontAwesomeIcon icon={ faInfoCircle } /> No hay datos para mostrar
                    </div>)
    } 

    componentDidMount()
    {
        if (this.props.solografico)
        {
            opciones.plugins.legend.display = !this.props.solografico
        } 

        if (this.props.sinanimation)
        {
            opciones.animation.duration = 0
        }
    }
}

export default ChartLines