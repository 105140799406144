import React from 'react'; 

import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 

import Menu from './menu';
import DatosSPG from './funciones.js';
import Fecha from './fecha';

const aZonasPublicas = require('../zonas-publicas.json');

class Contenedor extends React.Component
{
	constructor(props)
	{
		super(props)

		this.state = { 
						cargando: <div className="Contenedor text-center py-5">
										<h3 className='text-gradient'><FontAwesomeIcon className="text-resaltado" icon={ faSpinner } spin /> Cargando contenido, espere por favor...</h3>
									</div>,
					    modulo: [],
					   	tema: "" }
	}

	// Contenido de todo lo que sea la app, pantallas, consultas, abm, etc, empieza por el login y el menú de atajos 
	render()
	{
		//Para convertir un array a base64
        //const bParamDat1 = Buffer.from('{"idBD": 2, "idComprobante": 32048}'); 
        //console.log(bParamDat1.toString("base64"))

		if (this.state.modulo.length === 0)
		{
			return(<div>{ this.state.cargando }</div>);
		}
		else
		{
			return(<div>{ this.state.modulo }</div>);
		} 
	} 

	componentDidMount()
	{ 
		let bPublico = false 
		const url = window.location.href.split('/');
		
        //  0   1    2            3     4 
        //http://localhost:3000/pagar/parametros

		const cPantalla = url[3].split("?")[0].toLowerCase().split("#")[0] // esto devuelve siempre la pantalla
		let cSubModulo = ""
		
		if (url[4])
		{
			cSubModulo = "/" + url[4].split("?")[0].toLowerCase().split("#")[0] // esto devuelve siempre la subpantalla
		}
		
		// Recorre las zonas pubicas, si es una de ellas, no pasa por el modulo de seguridad, se muestran directamente
		for (let i = 0; i < aZonasPublicas.length; i++)
		{
			if (cPantalla.includes(aZonasPublicas[i]) || (cPantalla + cSubModulo).includes(aZonasPublicas[i]))
			{
				bPublico = true 
				i = aZonasPublicas.length
			}
		}

		if (bPublico)
		{
			// Las partes publicas siempre usan pantallas
			this.ImportarModulo(cPantalla)
		}
		else
		{
			const spg = new DatosSPG()

			const nUsuario = parseInt(spg.idUsuario())
			const cCertificado = spg.Certificado()
			const cKey = spg.Key() 
			const cToken = spg.getParameterByName("token")
			const cEquipo = spg.getParameterByName("equipo")
			
			if (cCertificado === null || cKey === null || cCertificado === "" || cKey === "")
			{
				spg.GuardarDato("volver", window.location.href)

				if (!spg.VerDato("Primera-Visita"))
				{
					const aUrl = window.location.href.split('/');
					
					// Si es el dominio de unipago, lo manda directamente a /pagar, ya que es donde directamente debe estar, 
					// ahi se maneja automáticamente el resto de los param
					if (aUrl[2] === "mi.unipago.app") 
					{
						this.ImportarModulo("pagar")
					}
					else 
					{
						// si la pantalla viene con un token, es porque quiere registrar el certificado, manda directo a certificados, previo marcar la visita, 
						// para que no se vaya de ahi cuando entre a los certificados
						if (cToken)
						{
							this.MarcarVisita()

							window.location = "/certificados?token=" + cToken + (cEquipo ? "&equipo=" + cEquipo : "")
						}
						else 
						{
							if (aUrl[2] === "mi.unipago.app")
							{
								window.location = "/pagar" 
							}
							else 
							{
								window.location = "/inicio" 
							}
						}
					}
				}
				else 
				{
					this.ImportarModulo("certificados")
				}
			}
			else 
			{
				if (nUsuario === 0 || nUsuario === null || nUsuario === "null" || nUsuario === "")
				{
					spg.GuardarDato("volver", window.location.href)
					
					this.ImportarModulo("login")
				}
				else
				{
					if (!spg.VerDato("bdNombreBDPagina"))
					{
						spg.BuscarDatosBD()
					}
					
					// Revisa que todos los datos esten cargados
					const aMonedas = spg.VerDato("Monedas", true)
					const aUnidades = spg.VerDato("Unidades", true)
					const aSucursales = spg.VerDato("Sucursales", true)
					const aUsuarios = spg.VerDato("Usuarios", true)
					const aConfiguraciones = spg.VerDato("Configuraciones", true)
					const aTiposDocumentos = spg.VerDato("TiposDocumentos", true)
					const aTiposContribuyentes = spg.VerDato("TiposContribuyentes", true)
					const aProvincias = spg.VerDato("Provincias", true)
					
					// cualquier dato que falte, manda limpiar la cache
					if ((aMonedas.length === 0 || aUnidades.length === 0 || aSucursales.length === 0 || aUsuarios.length === 0 || 
						aConfiguraciones.length === 0 || aTiposDocumentos.length === 0 || aTiposContribuyentes.length === 0 ||  
						aProvincias.length === 0) && cPantalla !== "limpiar-cache")
					{ 
						window.location = "/limpiar-cache/automatico"
					}
					else
					{				
						if (cPantalla !== "")
						{
							this.ImportarModulo(cPantalla)
						} 
						else
						{ 
							// Si no tiene nada, es la / , la home
							this.ImportarModulo("principal")
						}
					}
				}
			} 
		}
	}

	ImportarModulo(cPantalla)
	{
		try
		{
			const aUrl = window.location.href.split('/')
			
			console.log("Cargando módulo: " + cPantalla)
			
			switch (cPantalla.toLowerCase())
			{ 
				case "cambiar-moneda":
					import('../pantallas/cambiar-moneda.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) } 
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "imprimir-etiquetas":
					import('../pantallas/imprimir-etiquetas.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu /> 
																											{ React.createElement(xMod.default) } 
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "crear-qr":
					import('../pantallas/crear-qr.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu /> 
																												{ React.createElement(xMod.default) } 
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "consulta-stats-tienda":
					import('../pantallas/consulta-stats-tienda.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu /> 
																												{ React.createElement(xMod.default) } 
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "consulta-compras-web-pendientes":
					import('../pantallas/consulta-compras-web-pendientes.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																														<Menu /> 
																														{ React.createElement(xMod.default) }
																													</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "consulta-arbol-escalafones":
					import('../pantallas/consulta-arbol-escalafones.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																													<Menu /> 
																													{ React.createElement(xMod.default) }
																												</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "cierre-caja":
					import('../pantallas/cierre-caja.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "convertir-divisas":
					import('../pantallas/convertir-divisas.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu /> 
																											{ React.createElement(xMod.default) }
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "compras":
					import('../pantallas/compras.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu /> 
																									{ React.createElement(xMod.default) }
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "gastos":
					import('../pantallas/gastos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu /> 
																									{ React.createElement(xMod.default) }
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "ingreso-caja":
					import('../pantallas/ingresos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu /> 
																									{ React.createElement(xMod.default) }
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "remito-interno":
					import('../pantallas/remito-interno.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu /> 
																									{ React.createElement(xMod.default) }
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "remitos":
					import('../pantallas/remitos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu /> 
																									{ React.createElement(xMod.default) }
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "remito-rapido":
					import('../pantallas/remito-x-cliente-rapido.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																													<Menu /> 
																													{ React.createElement(xMod.default) }
																												</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "facturacion-periodica":
					import('../pantallas/facturacion-periodica.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "consulta-entidades":
					import('../pantallas/consulta-entidades.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "consulta-deudas":
					import('../pantallas/consulta-deudas.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "config-perfiles-compra":
					import('../pantallas/config-perfiles-compra.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu /> 
																												{ React.createElement(xMod.default) }
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "config-descuentos":
					import('../pantallas/config-descuentos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu /> 
																											{ React.createElement(xMod.default) }
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "zonas-envios":
					import('../pantallas/zonas-envios.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "config-pagos":
					import('../pantallas/config-pagos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu /> 
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "uala":
					import('../pantallas/uala.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor pl-2" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "mercadopago":
					import('../pantallas/mercado-pago.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor pl-2" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "paypal":
					import('../pantallas/paypal.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor pl-2" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "payway":
					import('../pantallas/payway.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor pl-2" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "stripe":
					import('../pantallas/stripe.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor pl-2" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "ayuda":
					import('../pantallas/ayuda.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu /> {
																								 React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "reportes":
					import('./reportes.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																						<Menu reporte={ true } /> 
																						{ React.createElement(xMod.default) }
																					</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "pagar":
					import('../pantallas/pagar.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "inicio":
					import('../pantallas/landing-page.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										{ React.createElement(xMod.default) }
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					
					break 
				case "pedido":
					import('../pantallas/pedido.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								{ React.createElement(xMod.default) }
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "cuenta-corriente":
					import('../pantallas/cuenta-corriente.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu /> 
																											{ React.createElement(xMod.default) }
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "certificados":
					import('../pantallas/certificados.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor gradiente" } id="Contenedor">
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "login":
					import('../pantallas/login.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor gradiente" } id="Contenedor">
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "limpiar-cache":
					import('../pantallas/limpiar-cache.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										{ aUrl[4] !== "automatico" ? <Menu /> : [] }
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "principal":
					// Si es el dominio de unipago, lo manda directamente a /pagar, ya que es donde directamente debe estar, ahi se maneja automáticamente el resto de los param
					if (aUrl[2] !== "mi.unipago.app") //  && aUrl[2] !== "localhost:3000"
					{
						import('../pantallas/principal.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 
					}
					else 
					{
						window.location = "/pagar" 
					}

					break  
				case "consulta-comprobantes":
					import('../pantallas/consulta-comprobantes.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu />
																												{ React.createElement(xMod.default) }	
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "consulta-ventas":
					import('../pantallas/consulta-ventas.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "facturacion":
					import('../pantallas/facturacion.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "opciones-comp":
					import('../pantallas/opciones-comp.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "recibos":
					import('../pantallas/recibos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu />
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "ordenes-pago":
					import('../pantallas/ordenes-pago.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break  
				case "comprobante":
					if (aUrl[5] && aUrl[5].toLowerCase() === "mas-datos")
					{
						import('../pantallas/mas-datos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 
					}
					else 
					{
						import('../pantallas/comprobante.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 
					}

					break 
				case "consulta-stock":
					import('../pantallas/consulta-stock.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "version":
					import('../pantallas/versionado.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "mis-datos":
					import('../pantallas/mis-datos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "mis-equipos":
					import('../pantallas/mis-equipos.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "mis-servicios":
					import('../pantallas/mis-servicios.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "panel-control":
					import('../pantallas/panel-control.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "fae-pendiente":
					import('../pantallas/fae-pendiente.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "actualizar-precios":
					import('../pantallas/actualizar-precios.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "listado":
					import('../componentes/listado.js').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "cobrar":
					import('../pantallas/cobrar').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																							<Menu />
																							{ React.createElement(xMod.default) }	
																						</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-ctacte-clientes":
					import('../pantallas/consulta-ctacte-clientes').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu />
																												{ React.createElement(xMod.default) }	
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-ctacte-proveedores":
					import('../pantallas/consulta-ctacte-proveedores').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																													<Menu />
																													{ React.createElement(xMod.default) }	
																												</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "pruebas":
					import('../pantallas/pruebas').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu />
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "cambiar-password":
					import('../pantallas/cambiar-clave').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																									<Menu />
																									{ React.createElement(xMod.default) }	
																								</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-entradas-salidas":
					import('../pantallas/consulta-entradas-salidas').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu />
																												{ React.createElement(xMod.default) }	
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-rapida-productos":
					import('../pantallas/consulta-rapida-productos').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																												<Menu />
																												{ React.createElement(xMod.default) }	
																											</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "switch":
					import('../pantallas/switch').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu />
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "banners":
					import('../pantallas/banners').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu />
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "importar-productos":
					import('../pantallas/importar-productos').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-ctacte-promociones":
					import('../pantallas/consulta-ctacte-promociones').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																													<Menu />
																													{ React.createElement(xMod.default) }	
																												</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "inventario":
					import('../pantallas/inventario').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																								<Menu />
																								{ React.createElement(xMod.default) }	
																							</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "fidelizacion":
					import('../pantallas/config-fidelizacion').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-cierres-caja":
					import('../pantallas/consulta-cierres-caja').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "consulta-cheques":
					import('../pantallas/consulta-cheques').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																										<Menu />
																										{ React.createElement(xMod.default) }	
																									</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				case "config-whatsapp-msj":
					import('../pantallas/config-whatsapp-msj').then((xMod) => this.setState({ modulo: <div className={ this.state.tema + "Contenedor" } id="Contenedor">
																											<Menu />
																											{ React.createElement(xMod.default) }	
																										</div> }, this.FinalizaCargaModulo(cPantalla))) 

					break 
				default:
					this.ModuloError()
			}
		}
		catch
		{
			this.ModuloError()
		} 
	}

	ModuloError()
	{
		this.setState({ modulo: <div className="text-center my-5">
										<Menu />

										<img src={ process.env.PUBLIC_URL + "/images/isologotipo-joyerias.svg" } alt="error" height="100" />
										<h1 className="text-red">¡Ups!</h1>
										<h3 className="text-red my-4">No se ha encontrado el módulo que estás buscando</h3>

										<span className="aclaraciones"><a href="/">Haga click aquí para poder ver más opciones</a></span>

										<button className="btn btn-primary my-5 text-upper" 
												onClick={ () => window.location = "/" }>
											<FontAwesomeIcon icon={ faCheck } /> Ver más Opciones
										</button>
									</div> })
	}

	FinalizaCargaModulo(cPantalla)
	{
		// aca hace todo lo necesario para la finalización de la carga de una pantalla 
		// aviso, traducción, etc

		console.log("Finalizada la carga del módulo " +  cPantalla)

		/*const spg = new DatosSPG()
		const cIdioma = spg.VerDato("Idioma")

		if (cIdioma)
		{
			console.log("Comienza la carga de la traducción de " +  cPantalla)

			
		}*/
	}

	MarcarVisita()
    {
        const dFecha = new Fecha()
        const spg = new DatosSPG()

        spg.GuardarDato("Primera-Visita", dFecha.Hoy())
    }
}

export default Contenedor;