import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Fecha from './fecha';
import DatosSPG from './funciones';
import Configuraciones from '../objetos/configuraciones';

class Comprobantes 
{
    constructor()
    {
        const oSPG = new DatosSPG()
        const oConfiguraciones = oSPG.VerDato("Configuraciones", true)
        let bAgruparProductos = 1
        let cConfValor = ""

        if (oConfiguraciones)
        {
            for (let i = 0; i < oConfiguraciones.length; i++)
            {
                if (oConfiguraciones[i].confDescrip === "AgruparProductos")
                {
                    cConfValor = oConfiguraciones[i].confValor
                }
            }
        }

        if (cConfValor === ".T.")
        {
            bAgruparProductos = 1
        }
        else
        {
            bAgruparProductos = 0
        }

        this.state = { 
                        spg: oSPG, 
                        AgruparProductos: bAgruparProductos,
                        TotalComprobante: 0,
                        CuentasBancarias: []
                     } 
    }

    /**
     * Crea comprobantes en forma automática, sin tener que estar haciendo una llamada a Leer API, 
     * sirve mayormente para comprobantes automáticos
     * 
     * Parametros:
     * cTipo, idDeposito, idProducto, cantidad, cTipoComprobante = "insert-ajuste"
    */
    async CrearComprobante(cTipo, idDeposito, idProducto, cantidad, cTipoComprobante = "insert-ajuste")
    {
        let nUsuario = this.state.spg.VerDato("idUsuario")
        let nSucursal = this.state.spg.VerDato("idSucursal")
        let nPuntoVenta = this.state.spg.VerDato("idPuntoVenta")

        return new Promise(async function(resolve, reject) 
        {
            // idProducto*, idUsuario*, idSucursal*, idPuntoVenta*, idDeposito*, cdCantidad*
            let aDatos = [{
                                "nombre": "tipo", 
                                "valor": cTipo
                        },
                        {
                                "nombre": "idProducto", 
                                "valor": idProducto
                        },
                        {
                                "nombre": "cdCantidad",
                                "valor": cantidad
                        },
                        {
                                "nombre": "idDeposito",
                                "valor": idDeposito
                        },
                        {
                                "nombre": "idSucursal",
                                "valor": nSucursal
                        },
                        {
                                "nombre": "idPuntoVenta",
                                "valor": nPuntoVenta
                        },
                        {
                                "nombre": "idUsuario",
                                "valor": nUsuario
                        }]

            const spg = new DatosSPG()
            const jRta = await spg.LeerAPI("comprobantes/", cTipoComprobante, aDatos)

            resolve(jRta)
        })
    }

    /**
     * Crea automáticamente un item de un comprobante
     * Devuelve el json con el ID y el estado
     */
    CrearDetalleComprobante(nProducto, nIdentificable, cDescrip, nCantidad, nIVA, nPrecio, nDescuento, cObservaciones, cClave, nModalidadVenta = 0, nPrecioNoGravado = "NULL")
    {
        const bSumar = this.state.AgruparProductos

        return new Promise(function(resolve, reject)
        {
            const spg = new DatosSPG();
            const aParametros = [{
                                    nombre: "idProducto",
                                    valor: nProducto
                                },
                                {
                                    nombre: "idIdentificable",
                                    valor: nIdentificable
                                },
                                {
                                    nombre: "cdCantidad",
                                    valor: nCantidad
                                },
                                {
                                    nombre: "cdDescuento",
                                    valor: nDescuento
                                },
                                {
                                    nombre: "cdClave",
                                    valor: cClave
                                },
                                {
                                    nombre: "cdPrecioUnit",
                                    valor: nPrecio
                                },
                                {
                                    nombre: "cdPrecioNoGravado",
                                    valor: nPrecioNoGravado
                                },
                                {
                                    nombre: "cdProDescrip",
                                    valor: cDescrip
                                },
                                {
                                    nombre: "cdIVA1",
                                    valor: nIVA
                                },
                                {
                                    nombre: "cdIVA2",
                                    valor: "NULL"
                                },
                                {
                                    nombre: "Sumar",
                                    valor: bSumar
                                },
                                {
                                    nombre: "CantidadActualPant",
                                    valor: "NULL"
                                },
                                {
                                    nombre: "cdObservaciones",
                                    valor: cObservaciones
                                },
                                {
                                    nombre: "cdModalidadVenta",
                                    valor: nModalidadVenta
                                }]
            
            spg.LeerAPI("comprobantes/", "insert-comdetalle", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve(jRta)
                }
                else
                {
                    console.log(aParametros)

                    // Agregar Tratamiento por error, en caso que sea 409 es porque la consulta vino vacía
                    if (jRta.status !== 409)
                    {
                        swal("Ouch!", "Error: Se produjo un error al los datos, inténtelo de nuevo por favor", "error")
                    }

                    reject(jRta)
                }
            })
        })
    }

    /**
     * 
     * @param {*} nEntidad: Es el monto por el cual genera la NC/ND
     * @param {*} nMonto: Es el monto por el cual genera la NC/ND
     * @param {*} nTipoComprobante: Es el id del tipo de comprobante seleccionado, si se manda en null, selecciona automáticamente el primero de tipo NC y si hay uno predeterminado, usa ese
     * @param {*} idProducto: Es el producto utilizado para hacer el documento, si se manda vacio o cero, se usa el eventual configurado
     * @param {*} nCantidad: Es la cantidad de el producto
     * @param {*} cObservaciones: Es la observacion del comprobante
     * @returns un JSON con el resultado de la operacion
     */
    CrearNCND(nEntidad, nMonto, nTipoComprobante = 0, idProducto = 0, nCantidad = 1, cObservaciones = "Modificación automatica de la cuenta corriente", nComprobanteAsociado = 0)
    {
        const spg = this.state.spg

        const aTiposComprobantes = spg.VerDato("TiposComprobantes", true)
        const nUsuario = spg.VerDato("idUsuario")
        const nSucursal = spg.VerDato("idSucursal")
        const nPuntoVenta = spg.VerDato("idPuntoVenta")
        const nDeposito = spg.VerDato("idDeposito")
        const cdClave = "ncnd-auto-" + this.GetClave()
        const dFecha = new Fecha()

        let cAbrev = ""
        let nIVA = "NULL"
        let bDiscriminaIVA = false 

        for (let k = 0; k < aTiposComprobantes.length; k++)
        {
            if (parseInt(aTiposComprobantes[k].idTipoComprobante) === parseInt(nTipoComprobante))
            {
                bDiscriminaIVA = aTiposComprobantes[k].tcDiscriminaIVA
            }
        }
        
        // Sólo si el tipo de comprobante seleccionado discrimina IVA y si la sucursal es RI, agrega el IVA
        if (bDiscriminaIVA)
        {
            const aSucursales = spg.VerDato("Sucursales", true)
            const aTiposContribuyentes = spg.VerDato("TiposContribuyentes", true)
            
            for (let i = 0; i < aSucursales.length; i++)
            {
                if (parseInt(aSucursales[i].idSucursal) === parseInt(nSucursal))
                {
                    for (let j = 0; j < aTiposContribuyentes.length; j++)
                    {
                        if (parseInt(aTiposContribuyentes[j].idTipoContribuyente) === parseInt(aSucursales[i].idTipoContribuyente))
                        {
                            cAbrev = aTiposContribuyentes[j].tyAbrev 
                        }
                    }
                }
            }

            if (cAbrev && cAbrev === "RI")
            {
                nIVA = 21
                nMonto = nMonto / 1.21 
            }
        }

        // Si viene vacio el tipo de ocmprobante, busca uno por default (solo tipo NC)
        if (nTipoComprobante === 0)
        {
            const aTiposComprobantes = this.state.spg.VerDato("TiposComprobantes", true)

            let nTipoCompDefa = 0
            let nTipoCompPrim = 0

            for (let i = 0; i < aTiposComprobantes.length; i++)
            {
                if (aTiposComprobantes[i].tcNotaCredito === "1" && nTipoCompPrim === 0)
                {
                    nTipoCompPrim = aTiposComprobantes[i].idTipoComprobante
                }
                
                if (aTiposComprobantes[i].tcNotaCredito === "1" && aTiposComprobantes[i].tcPredet === "1" && nTipoCompDefa === 0)
                {
                    nTipoCompDefa = aTiposComprobantes[i].idTipoComprobante
                }
            }

            if (nTipoCompDefa !== 0 || nTipoCompPrim !== 0)
            {
                if (nTipoCompDefa !== 0)
                {
                    nTipoComprobante = nTipoCompDefa
                }

                if (nTipoCompPrim !== 0)
                {
                    nTipoComprobante = nTipoCompPrim
                }
            }
            else
            {
                swal("ERROR", "No se ha configurado correctamente un comprobante por default tipo Nota de Crédito", "error")

                return false
            }
        }

        // idProducto*, idUsuario*, idSucursal*, idPuntoVenta*, idDeposito*, cdCantidad*
        const aDatos = [{
                            "nombre": "idEntidad", 
                            "valor": nEntidad
                        },
                        {
                            "nombre": "idTipoComprobante", 
                            "valor": nTipoComprobante
                        },
                        {
                            "nombre": "idProducto", 
                            "valor": idProducto
                        },
                        {
                            "nombre": "cdCantidad",
                            "valor": nCantidad
                        },
                        {
                            "nombre": "monto",
                            "valor": nMonto
                        },
                        {
                            "nombre": "cdIVA1",
                            "valor": nIVA
                        },
                        {
                            "nombre": "idDeposito",
                            "valor": nDeposito
                        },
                        {
                            "nombre": "idSucursal",
                            "valor": nSucursal
                        },
                        {
                            "nombre": "idPuntoVenta",
                            "valor": nPuntoVenta
                        },
                        {
                            "nombre": "idUsuario",
                            "valor": nUsuario
                        },
                        {
                            "nombre": "cdClave",
                            "valor": cdClave
                        },
                        {
                            "nombre": "comObservac",
                            "valor": cObservaciones
                        },
                        {
                            "nombre": "comFecha",
                            "valor": dFecha.FormatoParamSQL()
                        },
                        {
                            "nombre": "idComprobanteAsociar",
                            "valor": nComprobanteAsociado
                        }]
                        
        return new Promise(function(resolve, reject)
        {
            spg.LeerAPI("comprobantes/", "insert-ncnd", aDatos).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve(jRta)
                }
                else
                {
                    console.log(aDatos)
                    console.log(jRta)

                    // Agregar Tratamiento por error, en caso que sea 409 es porque la consulta vino vacía
                    if (jRta.status !== 409)
                    {
                        swal("Ouch!", "Error: Se produjo un error al los datos, inténtelo de nuevo por favor", "error")
                    }

                    reject(jRta)
                }
            })
        })
    }

    /**
     * Devuelve una cadena de texto con un numero unico para crear una clave o nombre unico
     * 
     * @returns string
     */
    GetClave()
    {
        const dFecha = new Date()
        const cRandom = Math.floor(Math.random() * 1000000).toFixed()

        return "POSSUM-" + cRandom + "-" + this.state.spg.VerDato("idUsuario") + "-" + dFecha.getFullYear().toString() + dFecha.getMonth().toString() + dFecha.getDate().toString() + dFecha.getHours().toString() + dFecha.getMinutes().toString() + dFecha.getSeconds().toString() + dFecha.getMilliseconds().toString()
    }
    
    /**
     * # Ver Próximo Número de comprobante
     * 
     * Devuelve el numero del siguiente comprobante, segun el tipo y punto de venta pasados por parametro
     * 
     * @param {*} nTipoComprobante: Indica el tipo de comprobante que esta usando y el cual queire saber el proximo numero
     * @param {*} nPuntoVenta: Indica en que punto de venta esta trabajando
     * @returns numero string
     */
    VerProximoNumero(nTipoComprobante, nPuntoVenta)
    {
        const aDatos = [{
                            "nombre": "idTipoComprobante", 
                            "valor": nTipoComprobante
                        },
                        {
                                "nombre": "idPuntoVenta", 
                                "valor": nPuntoVenta
                        }]

        return this.state.spg.LeerAPI("comprobantes/", "get-proximo-numero", aDatos)
    }
    
    /**
     * # Ver Comprobante
     * 
     * Devuelve una parte del comprobante señalado en el parámetro y que parte del mismo
     * 
     * @param {number} nComprobante: Indica cual es el comprobante a traer
     * @param {string} cParte: Indica la parte del comprobante que desea traer (CABECERA, DETALLE, DETALLEFP, CTACTE, HABERES, DIRECCIONES, FINANCIACIONES, PERCEPCIONES, SIGUIENTES, RETENCIONESPAGOS, CAMBIOS, PROPIEDADES, TOTAL)
     * @param {boolean} bCotizado: Indica si el comprobante viene cotizado en la moneda local
     * @returns array
     */
    VerComprobante(nComprobante, cParte = "", bCotizado = false, cToken = "")
    {
        const aDatos = [{
                            "nombre": "idComprobante", 
                            "valor": nComprobante
                        },
                        {
                            "nombre": "parte", 
                            "valor": cParte
                        },
                        {
                            "nombre": "cotizado", 
                            "valor": bCotizado ? "1" : 0
                        }]

        return this.state.spg.LeerAPI("comprobantes/", "get-comprobante", aDatos, cToken)
    }

    /**
     * # GetURL | Obtener URL del comprobante
     * 
     * @param {*} aCabecera: un array en el que está todo el comprobante, para obtener, el ID, tipo, etc #OJO, debe ser el comprobante, directo, no un array de comprobantes
     * @returns un STRING con la URL para compartir o imprimir
     */
    GetURL(aCabecera, nBD = 0)
    {
        if (aCabecera)
        {
            const spg = new DatosSPG()
            const conf = new Configuraciones()
            
            if (nBD === 0) 
            {
                nBD = spg.VerDato("idBD")
            }

            let cAgrupado = ""

            if (parseInt(conf.list.ImprimirMaterial) === 1)
            {
                cAgrupado = "M"
            }

            if (parseInt(conf.list.ImprimirCategoria) === 1)
            {
                cAgrupado = "C"
            }

            const bParamDat1 = Buffer.from('{"idBD": ' + nBD + ', "idComprobante": ' + aCabecera.idComprobante + ', "agrup": "' + cAgrupado + '"}') 
            let cReporte = ""
            let bTicket = ""

            if ((!aCabecera["tcReporte"] || aCabecera["tcReporte"] === "") && aCabecera["tcReporte"] !== "remito-donna")
            {
                if (aCabecera.tcFactura)
                {
                    bTicket = spg.VerDato("Ticket")

                    if (bTicket === "true")
                    {
                        cReporte = "ticket-" + aCabecera.tcLetra.toLowerCase()
                    }
                    else 
                    {
                        cReporte = "factura-" + aCabecera.tcLetra.toLowerCase()
                    }
                }

                if (aCabecera.tcPresupuesto || (aCabecera.tcLetra.toLowerCase() !== "a" && aCabecera.tcLetra.toLowerCase() !== "b" && aCabecera.tcLetra.toLowerCase() !== "c" && aCabecera.tcLetra.toLowerCase() !== "m" && aCabecera.tcLetra.toLowerCase() !== "x" && aCabecera.tcLetra.toLowerCase() !== "e"))
                {
                    bTicket = spg.VerDato("Ticket")

                    if (bTicket)
                    {
                        cReporte = "ticket-x"
                    }
                    else 
                    {
                        cReporte = "factura-x" 
                    }
                }
                
                if (aCabecera.tcNotaCredito)
                {
                    cReporte = "nota-credito-" + aCabecera.tcLetra.toLowerCase()
                }
                
                if (aCabecera.tcNotaDebito)
                {
                    cReporte = "nota-debito-" + aCabecera.tcLetra.toLowerCase()
                }
                
                if (aCabecera.tcRecibi)
                {
                    cReporte = "recibo-" + aCabecera.tcLetra.toLowerCase()
                }
                
                if (aCabecera.tcRemito)
                {
                    cReporte = "remito-" + aCabecera.tcLetra.toLowerCase()
                }
            }
            else
            {
                bTicket = spg.VerDato("Ticket")

                if (bTicket)
                {
                    cReporte = aCabecera.tcReporte.toLowerCase().replace("factura-", "ticket-")
                }
                else 
                {
                    cReporte = aCabecera.tcReporte.toLowerCase()
                }
            }

            return "/reportes/" + cReporte + "/" + bParamDat1.toString("base64")
        }
        else
        {
            return ""
        }
    }

    /**
     * # GetQR | Obtener el código QR para pagar
     * 
     * @param {*} aCabecera: un array en el que está todo el comprobante, para obtener, el ID, tipo, etc. Este se utilzia para buscar el monto total del comprobante
     * @param {*} nMonto: en caso de no venir la cabecera para buscar el dato del comprobante, viene el monto ya definido
     * @returns un STRING con la URL del QR para compartir o escanear
     */
    GetQR(aCabecera, nMonto = 0)
    {
        /*
            let cQR = ""

            if (aCabecera)
            {
                nMonto = this.VerTotalComprobante(aCabecera.idComprobante)
            }
            
            const aCuentas = await this.BuscarCuentas()

            console.log(aCuentas)

            let cRetorno = ""

            if (cQR !== "")
            {
                const bParamDat1 = Buffer.from(cQR)
                cRetorno = bParamDat1.toString("base64")
            }

            if (cRetorno !== "")
            {
                resolve(cRetorno)
            }
            else
            {
                reject ("")
            }
        })*/
    }

    /**
     * # Compartir comprobante
     * 
     * Abre la pantalla share nativa del navegador/os 
     * 
     * @param {*} aCabecera: un array en el que está todo el comprobante, para obtener, el ID, tipo, etc. Este se utilzia para buscar el monto total del comprobante
     * @returns BOOL según se haya podido o no pompartir
    */
    Compartir(aCabecera)
    {
        // Si el navegador no es compatible con la funcion SHARE, lo hace copiando el link al portapapeles
        try { 
            return navigator.share({
                                        title: aCabecera.comNumero,
                                        text: 'Visualizá y descargá tu ' + aCabecera.comNumero,
                                        url: "https://possum.com.ar" + this.GetURL(aCabecera)
                                    })
        } 
        catch(err) 
        {
            this.CopiarTexto("https://possum.com.ar" + this.GetURL(aCabecera))

            return true 
        }
    }

    /**
     * # Ver Total del Comprobante
     * 
     * Trae los totales de un comprobante
     * 
     * @param {*} nID: id INT del comprobante a traer 
     * @returns NUMERIC, total del comprobante
    */
    VerTotalComprobante(nID)
    {
        return new Promise(async function(resolve, reject)
        {
            const spg = new DatosSPG()
            const aParametros = [{
                                    nombre: "idComprobante", 
                                    valor: nID
                                },
                                {
                                    nombre: "parte", 
                                    valor: "TOTAL"
                                }]

            spg.LeerAPI("comprobantes/", "get-comprobante", aParametros).then((rta) => 
            {
                if (rta.status === 200)
                {
                    resolve(rta[0].Total)
                }
                else
                {
                    reject(0)
                }
            })
        })
    }

    CopiarTexto(cTexto)
    {
        document.getElementById("Copiar").style.display = "block"
        document.getElementById("Copiar").value = cTexto  
        document.getElementById("Copiar").select()
        document.execCommand('copy') 
        document.getElementById("Copiar").style.display = "none"

        toast.success('Se ha copiado al portapapeles', 
            {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: true,
            });
    }

    GenerarFAE(nID)
    {
        return new Promise(function(resolve, reject)
        {
            const spg = new DatosSPG()
            const cCUIT = spg.VerDato("sucCuit")
            const cURLFacturaElectronica = cCUIT.replace(/-/g, '').trim() + "-factura-electronica"

            const aParametros = [{
                                    nombre: "idComprobante", 
                                    valor: nID
                                },
                                {
                                    nombre: "idBD", 
                                    valor: spg.VerDato("idBD")
                                }]
            
            spg.LeerAPI("afip/", cURLFacturaElectronica, aParametros).then((jRta) =>
            {
                if (jRta.status === 200)
                {
                    if (jRta[0] === "OK")
                    {
                        resolve(jRta)
                    }
                    else
                    {
                        console.log(aParametros)
                        reject(jRta)
                    } 
                }
                else 
                {
                    console.log(aParametros)
                    reject(jRta)
                }
            })
        })
    }

    CopiarComprobante(nID)
    {
        const cClave = this.GetClave()

        return new Promise(function(resolve, reject)
        {
            const spg = new DatosSPG();
            const aParametros = [
                                {
                                    nombre: "idComprobante",
                                    valor: nID
                                },
                                {
                                    nombre: "cdClave",
                                    valor: cClave
                                }]
            
            spg.LeerAPI("comprobantes/", "copiar-comprobante", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    resolve({ tipo: jRta[0].Tipo, clave: cClave, perfil: jRta[0].idPerfil, idEntidad: jRta[0].idEntidad })
                }
                else
                {
                    console.log(aParametros)
                    console.log(jRta)

                    reject([])
                }
            }).catch((jRta) =>
            {
                console.log(aParametros)
                console.log(jRta)

                reject([])
            })
        })
    }
} 

export default Comprobantes;