import swal from 'sweetalert';
import { toast } from 'react-toastify';

import DatosSPG from "../componentes/funciones"

class Configuraciones
{
    /**
     * ## Configuraciones
     * 
     * ### Clase para manejar configuraciones
     * 
     * Clase que permite ver las configuraciones hechas para cada cliente en forma horizontal, es decir, como propiedades del objeto "list"
     * Sólo co instanciar la clase, llamar al objeto list, ya puede acceder a todas las configuraciones del cleinte
     * 
     * Por ej: 
     * 
     * const config = new Configuraciones() 
     * 
     * const.list.TituloLP1 // accede a la configuración para el título del precio especial 1
     */
    constructor()
    {
        const spg = new DatosSPG()
        const confOrig = spg.VerDato("ConfiguracionesHoriz", true)
        let aConfH = [{}]
        
        if (confOrig && confOrig.length > 0)
        {
            aConfH = confOrig[0]
        }
        else
        {
            const conf = spg.VerDato("Configuraciones", true)
            
            if (conf)
            {
                for (let i = 0; i < conf.length; i++)
                {
                    // crea un array horizontal de las configuraciones, para poder acceder más facilmente y sin tener que recorrer con for todas las veces
                    aConfH[0][conf[i].confDescrip] = conf[i].confValor 
                }
                
                spg.GuardarDato("ConfiguracionesHoriz", JSON.stringify(aConfH))
            }

            aConfH = aConfH[0]
        }
        
        this.list = aConfH
    }

    /**
     * ## Guardar automáticamente las configuraciones
     * ### También se ocupa de guardar en la base de datos local y en SQL
     * 
     * @param {*} e es el evento que lanza para actualizar (por ej, onBlur, onChange, sea cual sea el que utilice, automáticamente se busca el target y el valor y nombre del input que corresponde)
     * @param {*} cGrupo es el grupo de configuraciones Stock, Facturacion, General, Entidades, Externos, etc
     * @returns una promesa, cuando esta se cumpla, retorna un array
    */
    GuardarConfiguracion(e, cGrupo = "Externos")
    {
        const cDescrip = e.target.name
		let cValor = (e.target.type === "checkbox" ? (e.target.checked ? 1 : 0) : e.target.value)
        
        if (cValor === "")
        {
            switch (cDescrip)
            {
                case "PorcentajePrecioCdo":
                    cValor = "0"
                    break 
                case "AutoRedondeo":
                    cValor = "0.01"
                    break 
                case "AutoRedondeoWEB":
                    cValor = "0.01"
                    break 
                case "PorcentajeLP1":
                    cValor = "0"
                    break 
                case "PorcentajeLP2":
                    cValor = "0"
                    break 
                case "PorcentajeLP3":
                    cValor = "0"
                    break 
                case "PorcentajeLP4":
                    cValor = "0"
                    break 
                case "PorcentajeLP5":
                    cValor = "0"
                    break 
                case "DescuentoGeneral":
                    cValor = "0"
                    break 
                case "DescuentoGeneralPLista":
                    cValor = "0"
                    break 
                case "DescuentoGeneralPContado":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP1":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP2":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP3":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP4":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP5":
                    cValor = "0"
                    break 
                default:
            }
        }
        
        const spg = new DatosSPG();
		const aParametros = [
							{
								nombre: "Descrip",
								valor: cDescrip
							},
							{
								nombre: "Valor",
								valor: cValor === "on" ? "1" : cValor
							},
							{
								nombre: "Grupo",
								valor: cGrupo
							},
							{
								nombre: "idUsuario",
								valor: spg.VerDato("idUsuario")
							}] 
        
        return new Promise((resolve, reject) => 
        { 
            if (typeof this.list[cDescrip] === "undefined" || this.list[cDescrip].toString() !== cValor.toString())
            {
                spg.LeerAPI("base/", "update-configuraciones", aParametros).then((jRta) => 
                {
                    if (jRta.status === 200)
                    {
                        let confOrig = spg.VerDato("Configuraciones", true)
                        
                        for (let i = 0; i < confOrig.length; i++)
                        {
                            if (confOrig[i].confDescrip === cDescrip)
                            {
                                confOrig[i].confValor = (cValor === "on" ? "1" : cValor)
                            }
                        }
                        
                        spg.GuardarDato("Configuraciones", JSON.stringify(confOrig))
                        spg.EliminarDato("ConfiguracionesHoriz")

                        if (jRta[1] && jRta[1].versionCache)
                        { 
                            spg.GuardarDato("version-cache", JSON.stringify(jRta[1].versionCache))
                        }

                        toast.success('Configuración guardada correctamente', 
                            {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            }); 

                        resolve(jRta)
                    }
                    else
                    {
                        if (jRta.status !== 409)
                        {
                            swal("Ouch!", "Error: Se produjo un error al los actualizar datos, inténtelo de nuevo por favor", "error")
                        }

                        console.log(jRta)
                        console.log(aParametros)

                        reject(jRta)
                    }
                })
            }
            else
            {
                resolve(true)
            }
        })
    } 

    /**
     * ## Guardar configuraciones directamente desde codigo
     * ### También se ocupa de guardar en la base de datos local y en SQL
     * 
     * @param {*} e es el evento que lanza para actualizar (por ej, onBlur, onChange, sea cual sea el que utilice, automáticamente se busca el target y el valor y nombre del input que corresponde)
     * @param {*} cGrupo es el grupo de configuraciones Stock, Facturacion, General, Entidades, Externos, etc
     * @returns una promesa, cuando esta se cumpla, retorna un array
    */
    GuardarConfiguracionDirecto(cDescrip, cValor, cGrupo = "Externos")
    {
        if (cValor === "")
        {
            switch (cDescrip)
            {
                case "PorcentajePrecioCdo":
                    cValor = "0"
                    break 
                case "AutoRedondeo":
                    cValor = "0.01"
                    break 
                case "AutoRedondeoWEB":
                    cValor = "0.01"
                    break 
                case "PorcentajeLP1":
                    cValor = "0"
                    break 
                case "PorcentajeLP2":
                    cValor = "0"
                    break 
                case "PorcentajeLP3":
                    cValor = "0"
                    break 
                case "PorcentajeLP4":
                    cValor = "0"
                    break 
                case "PorcentajeLP5":
                    cValor = "0"
                    break 
                case "DescuentoGeneral":
                    cValor = "0"
                    break 
                case "DescuentoGeneralPLista":
                    cValor = "0"
                    break 
                case "DescuentoGeneralPContado":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP1":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP2":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP3":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP4":
                    cValor = "0"
                    break 
                case "DescuentoGeneralLP5":
                    cValor = "0"
                    break 
                case "PuntosPorPeso":
                    cValor = "0"
                    break 
                case "CompraMinimaPromo":
                    cValor = "0"
                    break 
                case "CtaCtePromo":
                    cValor = "0"
                    break 
                case "DescuentoPromo":
                    cValor = "0"
                    break 
                case "DescuentoXPuntos":
                    cValor = "0"
                    break 
                default:
            }
        }
        
        const spg = new DatosSPG();
		const aParametros = [
							{
								nombre: "Descrip",
								valor: cDescrip
							},
							{
								nombre: "Valor",
								valor: cValor === "on" ? "1" : cValor
							},
							{
								nombre: "Grupo",
								valor: cGrupo
							},
							{
								nombre: "idUsuario",
								valor: spg.VerDato("idUsuario")
							}] 
        
        return new Promise((resolve, reject) => 
        { 
            spg.LeerAPI("base/", "update-configuraciones", aParametros).then((jRta) => 
            {
                if (jRta.status === 200)
                {
                    let confOrig = spg.VerDato("Configuraciones", true)
                    
                    for (let i = 0; i < confOrig.length; i++)
                    {
                        if (confOrig[i].confDescrip === cDescrip)
                        {
                            confOrig[i].confValor = (cValor === "on" ? "1" : cValor)
                        }
                    }

                    spg.GuardarDato("Configuraciones", JSON.stringify(confOrig))
                    spg.EliminarDato("ConfiguracionesHoriz")

                    toast.success('Configuración guardada correctamente', 
                            {
                                position: "bottom-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined
                            }); 

                    resolve(true)
                }
                else
                {
                    if (jRta.status !== 409)
                    {
                        swal("Ouch!", "Error: Se produjo un error al los actualizar datos, inténtelo de nuevo por favor", "error")
                    }

                    console.log(jRta)
                    console.log(aParametros)

                    reject(false)
                }
            })
        })
    } 
}

export default Configuraciones;