import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/bootstrap.css';
import './css/index.css';
import './css/App.css';
import './css/App-Cel-740.css';
import './css/app-net-1100.css';
import './css/App-tab-800.css';
import './css/animaciones.css';
import './css/fuentes.css';
import './css/oscuro.css';

import Contenedor from './componentes/contenedor';
import Pie from './componentes/pie';
import DatosSPG from './componentes/funciones';

const cUrl = window.location.href.split('/')[2]

const spg = new DatosSPG()

const bOscuro = spg.VerDato("tema-oscuro") === "1" ? true : false
const cTema = bOscuro ? "tema-oscuro " : ""

ReactDOM.render(
	<React.StrictMode>
		<div id="contenedor-tema" className={ cTema }>
			<Contenedor />

			<div className="clear"></div>

			{ cUrl !== "mi.unipago.app" && cUrl !== "clickpay.com.ar" //&& cUrl !== "localhost:3000"
				?	<div>
						<Pie />
					</div>
				: 	[]
			}
		</div>
		
  	</React.StrictMode>, document.getElementById('root')
);

// Soporta Service Workers??
if (navigator.serviceWorker)
{
	navigator.serviceWorker.register("/sw.js")
}